import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { faPenToSquare, faMagnifyingGlass, faSpinner, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import useContextTournament from '../useContextTournament';

export default function NewCompetitorEvents(props) {
    const { competitorDetail, eventSelect, goBack } = props;
    const { associationCode, tournamentId } = useContextTournament();

    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchFor, setSearchFor] = useState('');

    const [loadingEvents, setLoadingEvents] = useState(false);
    const [qualifyingEvents, setQualifyingEvents] = useState([]);
    const [eventGroups, setEventGroups] = useState([]);

    const [modalTeamLookup, setModalTeamLookup] = useState(false);
    const [teams, setTeams] = useState([]);
    const [modalAddTeam, setModalAddTeam] = useState(false);
    const [divisionTypes, setDivisionTypes] = useState([]);
    const [newTeam, setNewTeam] = useState({ team_name: '', team_rank: '' });
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [addingTeamEvent, setAddingTeamEvent] = useState(null);


    //const authUsername = GetAuthUsername();

    useEffect(() => {
        if (props.editEvents) {
            //getQualifyingEvents();
        }
    }, []);
    useEffect(() => {
        if (competitorDetail)
            getQualifyingEvents();
    }, [competitorDetail?.student, tournamentId]);
    const toggleTeamLookup = () => {
        if (!modalTeamLookup) {
            getTeamLookupData();
        }
        setModalTeamLookup(!modalTeamLookup);
    };
    const toggleAddTeam = () => {
        if (!modalAddTeam) {
            getDivisionTypesData()
        }
        setModalAddTeam(!modalAddTeam);
    }

    const getTeamLookupData = async () => {
        const response = await fetch(`/api/competitor/${tournamentId}/qualifyingteams`); //?studentId=' + encodeURIComponent(competitorDetail.student_id));
        const rdata = await response.json();
        let teams = rdata.filter(t => t.rank_class_name === competitorDetail?.student?.rank?.rank_class_name);
        setSelectedTeam(teams[0]);
        setTeams(teams);
    }
    const getDivisionTypesData = async () => {
        const response = await fetch(`/api/tournament/${tournamentId}/divisiontypes`); //?studentId=' + encodeURIComponent(competitorDetail.student_id));
        const rdata = await response.json();
        let divTypes = rdata.filter(dt => dt.division_type_name === competitorDetail?.student?.rank_class_name);
        if (divTypes.length !== 1) {
            setDivisionTypes(divTypes);
        }
        else {
            setNewTeam({ ...newTeam, team_rank: divTypes[0].division_type_name });
            setDivisionTypes(divTypes);
        }
    }
    const getQualifyingEvents = async () => {
        if (tournamentId && competitorDetail && competitorDetail.student && competitorDetail.student.weight && competitorDetail.student.date_of_birth && competitorDetail.student.rank_id && competitorDetail.student.gender) {
            setLoadingEvents(true);

            const response = await fetch(`/api/competitor/${tournamentId}/qualifyingevents?weight=` + encodeURIComponent(competitorDetail.student.weight)
                + '&dateOfBirth=' + encodeURIComponent(competitorDetail.student.date_of_birth)
                + '&rankId=' + encodeURIComponent(competitorDetail.student.rank_id)
                + '&gender=' + encodeURIComponent(competitorDetail.student.gender)
                );
            const rdata = await response.json();
            if (qualifyingEvents) {
                let groups = getDistinctEventGroups(rdata);
                setEventGroups(groups);
            }

            setQualifyingEvents(rdata);
            setLoadingEvents(false);
        }
    };
    const getEventGroups = () => {
        if (eventGroups && eventGroups.length > 0) {
            return eventGroups;
        }
        else {
            if (competitorDetail.events) {
                let groups = getDistinctEventGroups(competitorDetail.events);
                return groups;
            }
        }
        return [];
    //    let groups = [];
    //    if (props.editEvents) {
    //        if (qualifyingEvents) {
    //            groups = Array.from(new Set(qualifyingEvents.map(e => e.event_group_name)));
    //        }
    //    }
    //    else {
    //        if (competitorDetail.events)
    //            groups = Array.from(new Set(competitorDetail.events.map(e => e.event_group_name)));
    //    }
    //    return groups;
    };
    const getDistinctEventGroups = (data) => {
        let groups = Array.from(new Set(data.map(e => e.event_group_id))).map(id => {
            return {
                event_group_id: id,
                event_group_name: data.find(e => e.event_group_id === id).event_group_name
            }
        });
        return groups;
    };
    const changeSelectedTeam = (e) => {
        let team = teams[e.target.value];
        setSelectedTeam(team);
    }
    const changeNewTeam = (e) => {
        var targetVar = e.target.name;
        var targetVal = e.target.value;

        setNewTeam({ ...newTeam, [targetVar]: targetVal });
    }
    const addTeam = () => {
        let clone = [...teams];
        clone.push({
            student_id: null,
            first_name: newTeam.team_name,
            rank_class_name: newTeam.team_rank,
            association_cd: competitorDetail.association_cd,
        });
        setTeams([...clone]);
        setNewTeam({ team_name: '', team_rank: '' });
        setSelectedTeam(clone.at(-1));

        toggleAddTeam();
    }
    const teamSelected = () => {
        if (eventSelect) {
            if (addingTeamEvent) {
                eventSelect({
                    ...addingTeamEvent,
                    team_student_id: selectedTeam.student_id,
                    team_name: selectedTeam.first_name,
                    team_rank: selectedTeam.rank_class_name,
                    team_association_cd: selectedTeam.association_cd,
                    team_competitor_id: selectedTeam.competitor_id,
                });
                setAddingTeamEvent(null);
            }
        }

        setSelectedTeam(null);
        toggleTeamLookup();
    }
    const eventClick = (data) => {
        if (eventSelect) {
            if (data.team_event_flag) {
                //check to see if the event is is eventsData already, if it is raise the handler with the data so that it is removed
                if (competitorDetail?.events?.some(es => es.event_type_id === data.event_type_id)) {
                    eventSelect(data);
                    //props.handleEventSelect(data);
                }
                else {
                    setAddingTeamEvent(data);
                    toggleTeamLookup();
                }

            }
            else {
                eventSelect(data);
            }
        }
    };
    const editCompetitor = () => {
        if (goBack)
            goBack();
    };
    return (
        <div className="row" >
            {/*{JSON.stringify(competitorDetail)}<br />*/}
            {/*eventGroups: {JSON.stringify(eventGroups)}<br/>*/}
            {/*qualifyingEvents: {JSON.stringify(qualifyingEvents)}*/}
            {competitorDetail ?
                <>
                    <div className="col-auto px-5 pt-2">
                        <div className="col">
                            <h1>{competitorDetail.student.first_name} {competitorDetail.student.last_name}
                                <small>
                                    <span className="form-label text-secondary mb-0 ms-2 h4">
                                        (
                                        {competitorDetail.student.gender}
                                        )
                                    </span>
                                </small>
                                <small>
                                    <FontAwesomeIcon onClick={editCompetitor} icon={faPenToSquare} className="float-end link-primary h4 ms-3" title="Edit Competitor Details" style={{ cursor: 'pointer' }} />
                                </small>
                            </h1>
                            <h6>
                                {competitorDetail.student.rank_name} Belt 
                                <span className="form-label text-secondary mb-0 ms-2">
                                    (
                                    {competitorDetail.student.rank_class_name}
                                    )
                                </span>

                            </h6>
                            <h6>Competition Age: <b>{moment(competitorDetail.student.date_of_birth).isValid() ? moment().startOf('year').diff(moment(competitorDetail.student.date_of_birth), 'years') : '-'}</b></h6>
                            {competitorDetail.student.height ?
                                <>
                                    <h6>
                                        Height: {competitorDetail.student.height ? (competitorDetail.student.height - (competitorDetail.student.height % 12)) / 12 : ''}'
                                        {competitorDetail.student.height ? competitorDetail.student.height % 12 : ''}"
                                        <span className="form-label text-secondary mb-0 ms-2">
                                            (
                                            <NumericFormat id="heightMetric" displayType="text" value={competitorDetail.student.height * 0.0254} decimalScale={2} suffix="m" fixedDecimalScale thousandSeparator="," />
                                            )
                                        </span>
                                    </h6>
                                </>
                                : null
                            }
                            {competitorDetail.student.weight ?
                                <>
                                    <h6>
                                        Weight: {competitorDetail.student.weight} lbs
                                        <span className="form-label text-secondary mb-0 ms-2">
                                            (
                                            <NumericFormat id="heightMetric" displayType="text" value={competitorDetail.student.weight * 0.45359237} decimalScale={1} suffix="kg" fixedDecimalScale thousandSeparator="," />
                                            )
                                        </span>
                                    </h6>
                                </>
                                : null
                            }
                        </div>
                        {competitorDetail.student.school ?
                            <div className="col align-self-end">
                                <hr />
                                <h5>{competitorDetail.student.school.school_name}</h5>
                                {competitorDetail.student.school.address ?
                                    <h6>{competitorDetail.student.school.address.city}, {competitorDetail.student.school.address.state_prov}</h6>
                                    : null}
                            </div>
                            : null}
                    </div>
                    <div className="col px-5 pt-3">
                        <div className="col-auto">
                            <h5>Events Registered {loadingEvents ? <FontAwesomeIcon icon={faSpinner} spin /> : null}</h5> 
                            <p className="text-secondary">Select from the events below.</p>
                        </div>
                        <div className="row">
                            {getEventGroups().map((g, i) =>
                                <>
                                    {(competitorDetail.events?.some(e => e.event_group_id === g.event_group_id && !e.team_event_flag && !e.override_flag)) || (props.editEvents && qualifyingEvents && qualifyingEvents.some(e => e.event_group_id === g.event_group_id && !e.team_event_flag && !e.override_flag)) ?
                                        <div key={'event_group_' + g.event_group_id + '_' + i}>
                                            <small className="mt-1">
                                                {g.event_group_name}
                                                <hr className="mt-0 mb-1" />
                                            </small>
                                            <div className="col-12">
                                                {props.editEvents && qualifyingEvents ? qualifyingEvents.filter(ev => ev.event_group_id === g.event_group_id && !ev.team_event_flag && !ev.override_flag).map((e, i) =>
                                                    /*<div className="col-auto">*/
                                                    <span key={i + '-' + e.event_type_id}
                                                        disabled={e.team_event_flag}
                                                        onClick={() => eventClick({ ...e, competitor_id: competitorDetail.competitor_id, first_name: competitorDetail.first_name, last_name: competitorDetail.last_name })}
                                                        className={`badge me-1 border border-1 ${competitorDetail.events?.some(es => es.event_type_id === e.event_type_id) ? 'text-bg-primary' : 'text-bg-dark'}`}
                                                        style={{ cursor: "pointer" }}>{e.event_type_name}</span>
                                                    /*</div>*/
                                                ) : null}
                                            </div>
                                        </div>
                                        : null
                                    }
                                </>
                            )
                            }
                            {(competitorDetail.events?.some(e => e.team_event_flag)) || (props.editEvents && qualifyingEvents && qualifyingEvents.some(e => e.team_event_flag)) ?
                                <>
                                    <small className="mt-1">
                                        Team Events
                                        <hr className="mt-0 mb-1" />
                                    </small>
                                    <div className="col-12">
                                        {props.editEvents && qualifyingEvents ? qualifyingEvents.filter(ev => ev.team_event_flag).map((e, i) =>
                                            <span key={e.event_type_id} disabled={e.team_event_flag}
                                                onClick={() => eventClick({ ...e, competitor_id: competitorDetail.competitor_id, first_name: competitorDetail.first_name, last_name: competitorDetail.last_name })}
                                                className={`badge me-1 border border-1 ${competitorDetail.events?.some(es => es.event_type_id === e.event_type_id) ? 'text-bg-primary' : 'text-bg-dark'}`}
                                                style={{ cursor: "pointer" }}>
                                                {e.event_type_name}{competitorDetail.events?.some(es => es.event_type_id === e.event_type_id) ? ' (' + competitorDetail.events.find(es => es.event_type_id === e.event_type_id).team_name + ')' : ''}
                                            </span>
                                        ) : null}
                                    </div>
                                </>
                                : null
                            }
                        </div>
                    </div>
                </>
                :
                null
            }
            {/*<div className="d-xl-none">XL</div>*/}
            {/*<div className="d-lg-none">LG</div>*/}
            {/*<div className="d-md-none">MD</div>*/}
            {/*<div className="d-sm-none">SM</div>*/}
            <Modal isOpen={modalTeamLookup} toggle={toggleTeamLookup} size="md" fade={true}>
                <ModalHeader toggle={toggleTeamLookup}>
                    <FontAwesomeIcon icon={faUsers} className="pe-2" />Team Lookup
                </ModalHeader>
                <ModalBody className="">
                    <div className="input-group mb-3">
                        <select className="form-select" onChange={changeSelectedTeam}>
                            {teams && teams.map((t,ix) =>
                                <option key={t.student_id} value={ix} selected={selectedTeam && selectedTeam.first_name === t.first_name}>{t.first_name} ({t.rank_class_name})</option>
                            )}
                        </select>
                        <button className="btn btn-primary" type="button" onClick={toggleAddTeam}><FontAwesomeIcon icon={faSquarePlus} className="" /></button>
                    </div>
                    <Modal isOpen={modalAddTeam} toggle={toggleAddTeam} size="sm" fade={true}>
                        <ModalHeader toggle={toggleAddTeam}>
                            <FontAwesomeIcon icon={faUsers} className="pe-2" />Add Team
                        </ModalHeader>
                        <ModalBody className="">
                            <label htmlFor="txtTeamName" className="form-label">Team Name</label>
                            <input id="txtTeamName" type="text" className="form-control" value={newTeam.team_name} onChange={changeNewTeam} name="team_name" />
                            {divisionTypes.length !== 1 ?
                                <>
                                    <label htmlFor="ddlTeamRank" className="form-label pt-2">Rank</label>
                                    <select id="ddlTeamRank" className="form-select" onChange={changeNewTeam} name="team_rank" >
                                        <option value=""></option>
                                        {divisionTypes && divisionTypes.map(t =>
                                            <option key={t.division_type_id} value={t.division_type_name} selected={newTeam.team_rank === t.division_type_name}>{t.division_type_name}</option>
                                        )}
                                    </select>
                                </>
                                :
                                <>
                                    <label className="form-label pt-2">Rank</label>
                                    <input type="text" value={newTeam.team_rank} className="form-control fw-bold" readOnly />
                                </>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddTeam}>
                                Cancel
                            </Button>
                            {/*<Button color="primary" onClick={addEventsForCompetitor} disabled={!(eventsToAdd && eventsToAdd.length > 0)}>*/}
                            <Button color="primary" disabled={newTeam.team_name === '' || newTeam.team_rank === ''} onClick={addTeam}>
                                Add Team
                            </Button>
                        </ModalFooter>
                    </Modal>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleTeamLookup}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={teamSelected} disabled={selectedTeam ? false : true}>
                        Select Team
                    </Button>
                </ModalFooter>
            </Modal>


        </div>
    );
}
