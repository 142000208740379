import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';


import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCashRegister, faCreditCard, faMoneyBills, faSpinner, faTriangleExclamation, faLock } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import StripePayment from './StripePayment';
import useContextTournament from '../useContextTournament';


export default function Payment(props) {

    const { disabled, amount, transactionCompleted } = props;
    const { associationCode, tournamentId } = useContextTournament();

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');

    const [modal, setModal] = useState(false);

    const options = {
        mode: 'payment',
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {/*...*/ },
        fields: {
            billingDetails: {
                address: {
                    country: 'never'
                }
            }
        }
    };

    const appearance = {
        theme: 'night',

        variables: {
            colorPrimary: '#da0909',
            colorBackground: '#212529',
            colorText: '#dee2e6',
            colorDanger: '#df1b41',
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSize: "1em",
            logoColor: 'dark',
            //spacingUnit: '2px',
            //borderRadius: '4px',
            // See all possible variables below
        }
    };



    const toggleModal = () => setModal(!modal);

    useEffect(() => {
        if (tournamentId) {
            fetch(`/api/pos/${tournamentId}/stripeconfig`).then(async (r) => {
                const { publishableKey } = await r.json();
                setStripePromise(loadStripe(publishableKey));
            });
        }
    }, [tournamentId]);
    useEffect(() => {
    //    fetch("/api/pos/4001/createpaymentintent")
    //        .then((res) => res.json())
    //        .then(({ clientSecret }) => {
    //            console.log('clientSecret', clientSecret);
    //            setClientSecret(clientSecret);
    //        });
    }, []);

    const processTransaction = async () => {
        if (amount !== undefined) {
            if (amount === 0) {
                if (transactionCompleted)
                    transactionCompleted();
            }
            else if (amount > 0) {
                fetch(`/api/pos/${tournamentId}/createpaymentintent`, {
                    method: 'POST',
                    body: amount,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then((res) => res.json())
                    .then(({ clientSecret }) => {
                        //console.log('clientSecret', clientSecret);
                        setClientSecret(clientSecret);
                        toggleModal();
                    });
            }
        }
        //if (amount === null) {
        //    console.log('here');
        //}
        //else if (amount && amount === 0) {
        //    console.log('there');
        //    // It is a zero amount registration, so let it proceed
        //    if (transactionCompleted)
        //        transactionCompleted();
        //}
        //else if (amount && amount > 0) {
        //    toggleModal();
        //}

    }
    const handlePaymentSuccess = async (pi) => {
        if (transactionCompleted)
            transactionCompleted({ method: 'Card', paymentIntent: pi });
        toggleModal();
    };

    return (
        <>
            <button type="button" className="w-100 btn btn-primary btn-lg" onClick={processTransaction} disabled={disabled} >
                {modal ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faCashRegister} className="pe-2" />
                }
                Pay Now 
                <span className="ms-1 d-inline-block d-sm-inline-block d-md-none d-lg-inline-block">{amount > 0 ? <>- <NumericFormat value={amount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></> : null}</span>
            </button>
            <Modal isOpen={modal} toggle={toggleModal} size="lg" fade={true} backdrop={"static"}>
                <ModalHeader toggle={toggleModal}>
                    <FontAwesomeIcon icon={faCashRegister} className="pe-2" />Payment
                </ModalHeader>
                <ModalBody className="">
                    <h5 className="text-center text-secondary">Amount Due:</h5>
                    <h1 className="text-center display-1">{amount ? <NumericFormat value={amount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : ''}</h1>
                    {clientSecret && stripePromise && (
                        <Elements stripe={stripePromise} options={{ clientSecret, options, appearance }}>
                            <StripePayment amount={amount} handlePaymentSuccess={handlePaymentSuccess} />
                        </Elements>
                    )
                    }
                </ModalBody>
                {/*<ModalFooter>*/}
                    {/*<Button color="secondary" onClick={toggleModal}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                {/*</ModalFooter>*/}
            </Modal>

        </>
    );

}
