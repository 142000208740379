import React, { useState, useEffect } from 'react';

export const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};
export const isNumericDefault = (n, defaultValue) => {
	return isNumeric(n) ? n : defaultValue;
};
export const isNullOrEmpty = (val) => {
	return (!val || val === undefined || val === "" || val.length === 0);
};
export const isNullOrEmptyDefault = (val, defaultValue) => {
	return isNullOrEmpty(val) ? val : defaultValue;
};
