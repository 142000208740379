import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';
/*import QRScanner from './QRScanner.tsx'*/

function DashboardCard(props) {

    const navigate = useNavigate();

    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 my-md-3 py-md-2 my-sm-1 py-sm-1 py-1 my-1">
            <div className="card h-100 text-center" onClick={() => {
                if (props.navigateTo) {
                    navigate(props.navigateTo);
                }
                else {
                    if (props.onClick !== undefined) {
                        props.onClick();
                    }
                }
            }}>
                <div className="card-body dash-button">
                    {props.icon ?
                        <FontAwesomeIcon icon={props.icon} className="" />
                        :
                        props.iconAlt ? <div className="icon-alt">{props.iconAlt}</div> : null
                    }
                    {props.iconImg ?
                        props.iconImg
                        :
                        null
                    }
                    <h5 className="card-title">{props.title}</h5>
                </div>
            </div>
        </div>
    );
}
export default DashboardCard;
