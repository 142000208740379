import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFloppyDisk, faPlus, faUserPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';
import moment from 'moment';
import useContextTournament from '../useContextTournament';

export default function FindStudentDefault(props) {
    const { foundStudents } = props;
    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();

    const [students, setStudents] = useState([]);

    const [findFirstName, setFindFirstName] = useState(null);
    const [findLastName, setFindLastName] = useState(null);
    const [findStudentId, setFindStudentId] = useState(null);
    const [findDOB, setFindDOB] = useState(null);
    const [findingStudent, setFindingStudent] = useState(false);


    const [modalAdd, setModalAdd] = useState(false);

    const toggleModalAdd = () => setModalAdd(!modalAdd);

    useEffect(() => {
    }, []);


    const findStudent = async (e) => {
        e.preventDefault();
        setFindingStudent(true);
        await apiFetch.get(`/api/${associationCode}/profile/students/find?tournamentId=${tournamentId}`
            + '&firstName=' + encodeURIComponent(findFirstName)
            + '&lastName=' + encodeURIComponent(findLastName)
            + '&dob=' + encodeURIComponent(moment(findDOB).format('YYYY-MM-DD')))
            .then(resp => {
                setStudents(resp.data);
                if (foundStudents)
                    foundStudents(resp.data);
                setFindingStudent(false);
            })
            .finally(() => {
                setFindingStudent(false);
            });

    };

    return (
        <>
            <h4 className="mb-4">Find Competitor</h4>
            <div className="row g-3 mb-4">
                <div className="col-sm-4">
                    <label htmlFor="firstName" className="form-label">First Name:</label>
                    <input type="text" className={`form-control ${(findFirstName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="firstName" onChange={(e) => setFindFirstName(e.target.value)} value={findFirstName ?? ''} />
                </div>
                <div className="col-sm-4">
                    <label htmlFor="lastName" className="form-label">Last Name:</label>
                    <input type="text" className={`form-control ${(findLastName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => setFindLastName(e.target.value)} value={findLastName ?? ''} />
                </div>
                <div className="col-sm-4">
                    <label htmlFor="dateOfBirth" className="form-label">Date of Birth:</label>
                    {/*<input type="text" className={`form-control ${(profileInfo.lastName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })} name="lastName" value={profileInfo.lastName ?? ''} />*/}
                    {/*<input type="text" className={`form-control ${('' ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => alert('setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })')} name="lastName" value={'' ?? ''} />*/}
                    <input type="date" required className={`form-control ${moment(findDOB).isValid() ? 'is-valid' : 'is-invalid'}`} id="dateOfBirth" value={findDOB ?? ''} onChange={(e) => setFindDOB(e.target.value)} name="dateOfBirth" />
                </div>
                <div className="col text-end">
                    <button type="submit" className="btn btn-secondary" disabled={(findFirstName ?? '') === '' || (findLastName ?? '') === '' || !moment(findDOB).isValid()} onClick={findStudent}>
                        <FontAwesomeIcon icon={findingStudent ? faSpinner : faMagnifyingGlass} spin={findingStudent} className="me-2" />
                        Find Competitor
                    </button>
                </div>
            </div>
        </>
    );

}
