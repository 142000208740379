import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faStar, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function StudentCardImageHex(props) {
    const { imageUrl, alt, size, resp } = props;

    const [baseHeight, setBaseHeight] = useState(180);
    const [baseWidth, setBaseWidth] = useState(200);

    useEffect(() => {
        switch (size) {
            case 'xs':
                setBaseHeight(45);
                setBaseWidth(50);
                break;
            case 'sm':
                setBaseHeight(90);
                setBaseWidth(100);
                break;
            case 'md':
                setBaseHeight(180);
                setBaseWidth(200);
                break;
            default:
                break;
        }
    }, []);

    return (
        <>
            {/*<div className="col d-sm-none border border-1">*/}
            {resp ?
                <>
                    <div className="hex bg-red mr-4 d-sm-none" style={{ width: `${baseWidth * 2 + 4}px`, height: `${baseHeight * 2 + 4}px` }} >
                        <div className="hex-background" style={{ top: "2px", left: "2px", width: `${baseWidth * 2}px`, height: `${baseHeight * 2}px` }} >
                            <img className="img img-fluid" style={{ position: "relative" }} src={imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />
                        </div>
                    </div>
                    <div className="hex bg-red mr-4 d-none d-sm-block" style={{ width: `${baseWidth + 4}px`, height: `${baseHeight + 4}px` }} >
                        <div className="hex-background" style={{ top: "2px", left: "2px", width: `${baseWidth}px`, height: `${baseHeight}px` }} >
                            <img className="img img-fluid" style={{ position: "relative" }} src={imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />
                        </div>
                    </div>
                </>
                :
                <div className="hex bg-red mr-4" style={{ width: `${baseWidth + 4}px`, height: `${baseHeight + 4}px` }} >
                    <div className="hex-background" style={{ top: "2px", left: "2px", width: `${baseWidth}px`, height: `${baseHeight}px` }} >
                        <img className="img img-fluid" style={{ position: "relative" }} src={imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt={alt} />
                    </div>
                </div>
            }
            {/*</div >*/}
            {/*d-md-none*/}
            {/*<div className="col d-none d-sm-block border border-1">
                <div className="hex mt-3 bg-red mr-4" style={{ width: "104px", height: "94px" }} >
                    <div className="hex-background" style={{ top: "2px", left: "2px", width: "100px", height: "90px" }} >
                        <img className="img img-fluid" style={{ position: "relative" }} src={imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt={alt} />
                    </div>
                </div>
            </div>*/}
        </>
    );

}
