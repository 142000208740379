import { PaymentElement, LinkAuthenticationElement, CardElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';

export default function StripePayment(props) {
    const { amount } = props;

    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentReady, setPaymentReady] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);


        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/testing/complete`,
            },
            redirect: "if_required",
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            setMessage(error.message);
        }
        else if (paymentIntent && paymentIntent.status === 'succeeded') {
            setMessage("Payment status: " + paymentIntent.status + " :)")
            if (props.handlePaymentSuccess)
                props.handlePaymentSuccess(paymentIntent);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    }

    const cardOptions = {
        iconStyle: "solid",
        style: {
            base: {
                color: "#dee2e6",
                backgroundColor: "#212529",
                fontWeight: "500",
                fontSize: "1em",
                fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fce883",
                },
            },
            invalid: {
                iconColor: "#ef2961",
                color: "#ef2961",
            },
        },
    };
    const handlePaymentChange = (e) => {
        if (e && e.complete === true) {
            setPaymentReady(true);
        }
        else {
            setPaymentReady(false);
        }
    }


    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col pb-4 pt-4">
                    <PaymentElement id="payment-element" onChange={handlePaymentChange} />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="d-grid gap-2 col-lg-6 col-md-9 col-sm-12 mx-auto">
                    <button disabled={!paymentReady || isLoading || !stripe || !elements} id="submit" className="btn btn-primary btn-lg">
                        <span id="button-text">
                            {isLoading ?
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin className="px-2" /> Processing
                                </>
                                :
                                <>
                                    Charge - <NumericFormat value={amount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                </>
                            }
                        </span>
                    </button>
                </div>
            </div>
            <div className="row justify-content-center py-3">
                <div className="col text-center">
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                </div>
            </div>
        </form>
    )
}
