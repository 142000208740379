import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { isNumeric, isNumericDefault, isNullOrEmpty, isNullOrEmptyDefault } from '../../utilities';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faBuilding, faTriangleExclamation, faArrowLeft, faSchool, faPenToSquare, faRotateLeft, faXmark, faFloppyDisk, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useLookUpData } from '../useLookUpData';
import NewSchoolModal from './NewSchoolModal';
import useApiFetch from '../../ApiFetch';
import useContextTournament from '../useContextTournament';


//AIzaSyAYnsFi0nOknz3yvEm1cYVvsgWYoS1MUsE
export const NewCompetitor = forwardRef((props, ref) => {
    //const lookUpData = ;
    useImperativeHandle(ref, () => ({
        save(e) {
            saveClick(e);
        }
    }));

    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();

    const { getRanks, getSchools } = useLookUpData();
    const { associationId } = useParams();
    const { createNew, saved, competitorData } = props;
    const navigate = useNavigate();

    const [ranks, setRanks] = useState([]);
    const [schools, setSchools] = useState([]);

    const studentEmptyObj = {
        student: {
            first_name: '',
            last_name: '',
            association_id: '',
            date_of_birth: null,
            height: null,
            weight: null,
            school_id: null,
            school: {
                school_id: null,
                school_name: "",
                address_id: null,
                association_cd: "",
                address: {
                    address_id: null,
                    address_line_1: "",
                    city: "",
                    state_prov: "",
                    postal_code: "",
                    latitude: null,
                    longitude: null,
                    address_type: "",
                    location_name: null,
                    country_cd: null
                }
            },
            rank_id: null,
            rank_name: "",
            rank_class_name: "",
            gender: "",
            student_image_url: "",
            association_cd: associationCode,
            competition_age: null
        }
    };
    const [data, setData] = useState(studentEmptyObj);
    const [origData, setOrigData] = useState(studentEmptyObj);

    const [hasChanges, setHasChanges] = useState(null);
    const [saving, setSaving] = useState(false);

    const [loadingEvents, setLoadingEvents] = useState(false);

    const [saveStudentValidated, setSaveStudentValidated] = useState(null);
    const saveStudentForm = useRef(null);

    const [validatingAssociationId, setValidatingAssociationId] = useState(false);
    const [associationIdValid, setAssociationIdValid] = useState(true);
    const [isAssociationIdValidated, setIsAssociationIdValidated] = useState(false);

    const [modalRageWarning, setModalRageWarning] = useState(false);
    const [modalNewSchool, setModalNewSchool] = useState(false);

    const toggleModalRageWarning = () => setModalRageWarning(!modalRageWarning);
    const toggleModalNewSchool = () => setModalNewSchool(!modalNewSchool);

    const schoolLookUpRef = useRef(null);


    const [address, setAddress] = useState(null);

    useEffect(() => {
        getLookUpData();
        //if (!createNew) {
        //    getData();
        //}
        //else {
        if (competitorData) {
            setData({ ...competitorData });
            setOrigData({ ...competitorData });
        }
        else {
            setData({ ...studentEmptyObj });
            setOrigData({ ...studentEmptyObj });
        }

    //    }
    }, [associationId]);
    const getLookUpData = async () => {
        setRanks(await getRanks());
        setSchools(await getSchools());
    };
    const checkRageChanges = () => {
        let changes = false;
        if (data && origData && data.student && origData.student && (data.student.rank_id !== origData.student.rank_id || data.student.date_of_birth !== origData.student.date_of_birth || data.student.gender !== origData.student.gender || data.student.weight !== origData.student.weight)) {
            changes = true;
        }
        return changes;
    };
    const saveClick = async (event) => {
        if (checkRageChanges() && data && data.events && data.events.length > 0) { //data.divisionOverrides && data.divisionOverrides.length > 0) {
            setModalRageWarning(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            handleSaveStudent(event);
            event.preventDefault();
            event.stopPropagation();
        }
    };
    const handleSaveStudent = async (event, clearEvents) => {
        let form = saveStudentForm.current;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setSaveStudentValidated(true);
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            setSaveStudentValidated(true);
            if (!validateAssociationId()) {
                return;
            }

            setSaving(true);

            let thisData = data;
            thisData.student.date_of_birth = moment(thisData.student.date_of_birth).isValid() ? moment(thisData.student.date_of_birth).format("YYYY-MM-DD") : null;
            if (thisData.student) {
                if (!thisData.student.association_cd) {
                    thisData.student.association_cd = associationCode;
                }
                if (!(thisData.student.school && thisData.student.school.asscociation_cd)) {
                    thisData.student.school.asscociation_cd = associationCode;
                }
            }

            let refreshSchools = false;
            if (!thisData.student.school_id && thisData.student.school_name) {
                //if we don't have a school_id then we're likely adding one
                refreshSchools = true;
            }
            if (clearEvents) {
                thisData = { ...thisData, events: null }
            }

            if (saved) {
                saved(thisData);
            }
            setData({ ...thisData });

            if (createNew) {
                await apiFetch.post('/api/student', thisData.student)
                    .then((resp) => {
                        if (saved)
                            saved(resp.data);
                    })
                    .catch((ex) => {
                    })
                    .finally(() => {
                    });
            }
            setSaving(false);
            setAssociationIdValid(true);
            setIsAssociationIdValidated(false);
            setSaveStudentValidated(false);
            setValidatingAssociationId(false);
            setHasChanges(false);

            if (refreshSchools)
                setSchools(await getSchools(true));

        }
    };
    const checkChanges = (newData) => {
        if (!newData) {
            newData = data;
        }
        return origData && data
            && (
                origData.student.first_name !== newData.student.first_name
                || origData.student.last_name !== newData.student.last_name
                || origData.student.association_id !== newData.student.association_id
                || origData.student.rank_id !== newData.student.rank_id
                || !moment(origData.student.date_of_birth).isSame(newData.student.date_of_birth)
                || origData.student.gender !== newData.student.gender
                || origData.student.height !== newData.student.height
                || origData.student.weight !== newData.student.weight
                || origData.student.school_id !== newData.student.school_id
                || origData.student.school_name !== newData.student.school_name
            );
    };
    const setStudentData = (newData) => {
        setData({ ...data, student: { ...data.student, ...newData } });
        setHasChanges(checkChanges({ ...data, student: { ...data.student, ...newData } }));
    };
    const validateAssociationId = async (e) => {
        if (data.student?.association_id && origData.student?.association_id !== data.student?.association_id) {
            setValidatingAssociationId(true);
            const response = await fetch('/api/UFAF/competitor/validateassociationid/?associationId=UFAF-' + (e ? encodeURIComponent(e.target.value) : data.student.association_id));
            const rdata = await response.json();
            setAssociationIdValid(rdata);
            setIsAssociationIdValidated(true);
            setValidatingAssociationId(false);
            return false;
        }
        else {
            setAssociationIdValid(true);
            setIsAssociationIdValidated(true);
            return true;
        }
    };
    const getDistinctQualEventTypes = () => {
        if (data && data.qualifyingEvents) {
            let eventTypes = Array.from(new Set(data.qualifyingEvents.map(e => e.event_type_id))).map(id => {
                return {
                    event_type_id: id,
                    event_type_name: data.qualifyingEvents.find(e => e.event_type_id === id).event_type_name
                }
            });
            return eventTypes;
        }
        return [];
    };
    const setRank = async (e) => {
        if (e.target.value) {
            let selectedRank = null;
            if (ranks && ranks.length > 0) {
                selectedRank = ranks.find(r => r.rank_id === Number(e.target.value));
            }
            if (selectedRank) {
                setStudentData({ ...data.student, rank_id: selectedRank.rank_id, rank_name: selectedRank.rank_name, rank_class_name: selectedRank.rank_class_name })
            }
        }
        else {
            setStudentData({ ...data.student, rank_id: '', rank_name: '', rank_class_name: '' })
        }
    };

    const childRef = useRef();

    const openNewSchool = (school) => {
        if (childRef.current) {
            childRef.current.openNewSchool(school);
        }
    };
    const onSchoolAdded = (school) => {
        if (school) {
            setStudentData({ ...data.student, school_id: school.school_id === undefined ? null : school.school_id, school: school });
            //setStudentData({ ...data.student, school_id: s[0].customOption ? null : Number(s[0].school_id), school_name: s[0].school_name })
            schoolLookUpRef.current?.blur();
        }
        else
            clearSchoolLookup();
    };
    const clearSchoolLookup = (e) => {
        schoolLookUpRef.current?.clear();
        setStudentData({ ...data.student, school: null });
    };

    return (
        <>
            <div className="container-xl px-2 pb-4">
                <div className="row" >
                    <p className="text-secondary">
                        Enter the competitor details below.
                    </p>
                    {/*<StudentInfo />*/}
                    {/*<div className="d-flex mb-3 g-3">*/}
                    {/*    <ol className="me-auto list-group breadcrumb ps-3">*/}
                    {/*        <li className="breadcrumb-item">*/}
                    {/*            <Link onClick={() => navigate(-1)} className="link-body-emphasis fw-semibold text-decoration-none">*/}
                    {/*                <FontAwesomeIcon icon={faArrowLeft} className="pe-2" />*/}
                    {/*                <span className="">Back</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ol>*/}
                    {/*    <div className="col-auto g-3">*/}
                    {/*        {!createNew ?*/}
                    {/*            <button className="btn btn-secondary btn-sm ms-2" onClick={() => getData()} disabled={!hasChanges}>*/}
                    {/*                <FontAwesomeIcon icon={faRotateLeft} /> Undo Changes*/}
                    {/*            </button>*/}
                    {/*            :*/}
                    {/*            <button className="btn btn-secondary btn-sm ms-2" onClick={() => navigate(-1)}>*/}
                    {/*                <FontAwesomeIcon icon={faXmark} /> Cancel*/}
                    {/*            </button>*/}
                    {/*        }*/}
                    {/*        <button className="btn btn-primary btn-sm ms-2" onClick={saveClick} disabled={!hasChanges || (isAssociationIdValidated && !associationIdValid)}>*/}
                    {/*            <FontAwesomeIcon icon={saving ? faSpinner : faFloppyDisk} spin={saving} className="" /> Save*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{data && data.student ?*/}
                    {/*    <>*/}
                    {/*{JSON.stringify(data)}*/}

                    {data && data.student ?
                        <div className="col-12 px-3 pt-2">
                            <form ref={saveStudentForm} noValidate className={`row g-3 needs-validation ${saveStudentValidated ? 'was-validated' : ''}`} onSubmit={handleSaveStudent}>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input type="text" name="firstName" value={data.student.first_name} onChange={(e) => setStudentData({ ...data.student, first_name: e.target.value })} className="form-control" id="firstName" required />
                                    <div className="invalid-feedback">
                                        First name is required.
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input type="text" name="lastName" value={data.student.last_name} onChange={(e) => setStudentData({ ...data.student, last_name: e.target.value })} className="form-control" id="lastName" required />
                                    <div className="invalid-feedback">
                                        Last name is required.
                                    </div>
                                </div>
                                {/*<div className="col-md-6 col-sm-6 col-4">*/}
                                {/*    <label htmlFor="assocationId" className="form-label">Member Id</label>*/}
                                {/*    <div className="input-group">*/}
                                {/*        <NumericFormat*/}
                                {/*            id="assocationId"*/}
                                {/*            value={data.student.association_id ? data.student.association_id.replace('UFAF-', '') : ''}*/}
                                {/*            className={`form-control text-end ${!data.student.association_id ? 'is-invalid' : origData.student.association_id === data.student.association_id ? '' : isAssociationIdValidated ? (associationIdValid ? 'is-valid' : 'is-invalid') : ''}`}*/}
                                {/*            decimalScale={0}*/}
                                {/*            fixedDecimalScale*/}
                                {/*            required*/}
                                {/*            onValueChange={(v, si) => {*/}
                                {/*                if (si.source !== 'event')*/}
                                {/*                    return;*/}
                                {/*                let newValue = v.floatValue;*/}
                                {/*                if (newValue) {*/}
                                {/*                    setStudentData({ ...data.student, association_id: 'UFAF-' + v.floatValue });*/}
                                {/*                }*/}
                                {/*                else {*/}
                                {/*                    setStudentData({ ...data.student, association_id: '' });*/}
                                {/*                }*/}
                                {/*                setIsAssociationIdValidated(false);*/}
                                {/*                console.log(!data.student.association_id ? 'is-invalid' : origData.student.association_id === data.student.association_id ? '' : associationIdValid ? 'is-valid' : 'is-invalid');*/}
                                {/*            }}*/}
                                {/*            onFocus={e => e.target.select()}*/}
                                {/*            onBlur={validateAssociationId}*/}
                                {/*        />*/}
                                {/*        {validatingAssociationId ?*/}
                                {/*            <span className="input-group-text bg-transparent"><FontAwesomeIcon icon={faSpinner} spin className="" /></span>*/}
                                {/*            :*/}
                                {/*            null*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*    */}{/*<input type="associationCode" value={data.student.association_id ? data.student.association_id.replace('UFAF-', '') : ''} onChange={(e) => setStudentData({ ...data.student, assocation_id: 'UFAF-' + e.target.value })} className="form-control" id="associationCode" required />*/}
                                {/*    <div className="invalid-feedback">*/}
                                {/*        Member Id is required.*/}
                                {/*    </div>*/}
                                {/*    {isAssociationIdValidated && !associationIdValid && origData.student.association_id !== data.student.association_id*/}
                                {/*        ?*/}
                                {/*        <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '0.875em', color: 'var(--bs-form-invalid-color)' }}>*/}
                                {/*            The Member Id is already in use.*/}
                                {/*        </div>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*    }*/}

                                {/*</div>*/}
                                <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                    <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                                    <input type="date" name="dateOfBirth" value={data.student.date_of_birth ? moment(data.student.date_of_birth).format('yyyy-MM-DD') : ''} onChange={(e) => { setStudentData({ ...data.student, date_of_birth: e.target.value }) }} className="form-control" id="dateOfBirth" required />
                                    <small className="form-label text-secondary float-end mb-0">
                                        Competition Age: <b>{moment(data.student.date_of_birth).isValid() ? moment().startOf('year').diff(moment(data.student.date_of_birth), 'years') : '-'}</b>
                                    </small>
                                    <div className="invalid-feedback">
                                        Date of Birth is required.
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <label htmlFor="rank" className="form-label">Rank</label>
                                    <select id="rank" className="form-select" value={data.student.rank_id ?? ''}
                                        onChange={setRank}
                                        required>
                                        <option value="">Choose...</option>
                                        {ranks && ranks.length > 0 ?
                                            ranks.map((r, i) =>
                                                <option key={i} value={r.rank_id}>{r.rank_name} {/*({r.rank_class_name})*/}</option>
                                            )
                                            :
                                            null
                                        }
                                    </select>
                                    <small className="form-label text-secondary float-end mb-0">
                                        <b>{data?.student?.rank_class_name ? data?.student?.rank_class_name : '-'}</b>
                                    </small>
                                    <div className="invalid-feedback">
                                        Rank is required.
                                    </div>
                                </div>
                                {/*<div className="col-md-3 col-sm-4 col-4">*/}
                                {/*    <label className="form-label">Comp. Age</label>*/}
                                {/*    <input type="text" readOnly value={moment(data.student.date_of_birth).isValid() ? moment().startOf('year').diff(moment(data.student.date_of_birth), 'years') : '-'} className="form-control-plaintext" />*/}
                                {/*</div>*/}
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <label htmlFor="gender" className="form-label">Gender</label>
                                    <select id="gender" className="form-select" value={data.student.gender} onChange={(e) => setStudentData({ ...data.student, gender: e.target.value })} required>
                                        <option value="">--</option>
                                        <option>F</option>
                                        <option>M</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        Gender is required.
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <label htmlFor="heightFt" className="form-label">Height</label>
                                    <div className="d-flex">
                                        <select id="heightFt" className="form-select ignore-validation" value={data.student.height ? (data.student.height - (data.student.height % 12)) / 12 : ''} onChange={(e) => setStudentData({ ...data.student, height: e.target.value ? (Number(e.target.value) * 12) + (data.student.height % 12) : null })}>
                                            <option value="">ft</option>
                                            <option value="2">2'</option>
                                            <option value="3">3'</option>
                                            <option value="4">4'</option>
                                            <option value="5">5'</option>
                                            <option value="6">6'</option>
                                            <option value="7">7'</option>
                                        </select>
                                        <select id="heightIn" className="form-select ignore-validation ms-1" value={data.student.height ? data.student.height % 12 : ''} onChange={(e) => setStudentData({ ...data.student, height: e.target.value ? ((data.student.height - (data.student.height % 12))) + Number(e.target.value) : null })}>
                                            <option value="">in</option>
                                            <option value="0">0"</option>
                                            <option value="1">1"</option>
                                            <option value="2">2"</option>
                                            <option value="3">3"</option>
                                            <option value="4">4"</option>
                                            <option value="5">5"</option>
                                            <option value="6">6"</option>
                                            <option value="7">7"</option>
                                            <option value="8">8"</option>
                                            <option value="9">9"</option>
                                            <option value="10">10"</option>
                                            <option value="11">11"</option>
                                        </select>
                                    </div>
                                    <small>
                                        {data.student.height ?
                                            <NumericFormat id="heightMetric" displayType="text" value={data.student.height * 0.0254} className="form-label text-secondary float-end mb-0" decimalScale={2} suffix="m" fixedDecimalScale thousandSeparator="," />
                                            : null
                                        }
                                    </small>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <label htmlFor="weight" className="form-label">Weight (lbs)</label>
                                    <NumericFormat id="weight" value={data.student.weight} className="form-control text-end" decimalScale={0} fixedDecimalScale thousandSeparator="," required onValueChange={(v, si) => {
                                        if (si.source !== 'event')
                                            return;
                                        setStudentData({ ...data.student, weight: v.floatValue })
                                    }}
                                        onFocus={e => e.target.select()}
                                    />
                                    <small>
                                        {data.student.weight ?
                                            <NumericFormat id="heightMetric" displayType="text" value={data.student.weight * 0.45359237} className="form-label text-secondary float-end mb-0" decimalScale={1} suffix="kg" fixedDecimalScale thousandSeparator="," />
                                            : null
                                        }
                                    </small>
                                    <div className="invalid-feedback">
                                        Weight is required.
                                    </div>
                                </div>
                                {/*    <div className="col-12">*/}
                                {/*        <label for="inputAddress" className="form-label">Address</label>*/}
                                {/*        <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-12">*/}
                                {/*        <label for="inputAddress2" className="form-label">Address 2</label>*/}
                                {/*        <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-6">*/}
                                {/*        <label for="inputCity" className="form-label">City</label>*/}
                                {/*        <input type="text" className="form-control" id="inputCity" />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-4">*/}
                                {/*        <label for="inputState" className="form-label">State</label>*/}
                                {/*        <select id="inputState" className="form-select">*/}
                                {/*            <option selected>Choose...</option>*/}
                                {/*            <option>...</option>*/}
                                {/*        </select>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-2">*/}
                                {/*        <label for="inputZip" className="form-label">Zip</label>*/}
                                {/*        <input type="text" className="form-control" id="inputZip" />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-12">*/}
                                {/*        <div className="form-check">*/}
                                {/*            <input className="form-check-input" type="checkbox" id="gridCheck" />*/}
                                {/*                <label className="form-check-label" for="gridCheck">*/}
                                {/*                    Check me out*/}
                                {/*                </label>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-12">*/}
                                {/*        <button type="submit" className="btn btn-primary">Sign in</button>*/}
                                {/*    </div>*/}
                            </form>
                            <hr />
                            <div className="row g-3">
                                <div className={`col-md-12 needs-validation ${data.student.school_id === origData.student.school_id ? '' : 'was-validated'}`}>
                                    <label htmlFor="schoolLookup" className="form-label">School</label>
                                    <div className="input-group has-validation mb-3">
                                        <Typeahead
                                            id="schoolLookup"
                                            allowNew
                                            selected={schools && data.student && data.student.school
                                                ?
                                                (data.student.school.school_id)
                                                    ?
                                                    schools.filter(x => Number(x.school_id) === Number(data.student.school.school_id))
                                                    :
                                                    [{ school_id: null, school_name: data.student.school.school_name }]
                                                :
                                                null
                                            }
                                            /*defaultSelected={schools ? schools.filter(x => Number(x.school_id) === Number(data.student.school_id)) : (data.student.school_name ? [{ school_id: null, school_name: data.student.school_name }] : null)}*/
                                            newSelectionPrefix="Add new school: "
                                            labelKey={'school_name'}
                                            onChange={(s) => {
                                                if (s.length === 0) {
                                                    setStudentData({ ...data.student, school: null, school_id: null })
                                                }
                                                else {
                                                    //if (s[0].customOption) {
                                                    //    setNewSchool({ ...newSchool, school_name: s[0].school_name });
                                                    //    toggleModalAddSchool();
                                                    //}
                                                    //else
                                                    if (s[0].customOption) {
                                                        openNewSchool({ school_name: s[0].school_name, association_cd: associationCode });
                                                        //    toggleModalNewSchool();
                                                    }
                                                    else {
                                                        let thisSchool = schools?.find(sch => sch.school_id === Number(s[0].school_id));
                                                        setStudentData({ ...data.student, school: thisSchool, school_id: thisSchool.school_id });
                                                        //school_id: s[0].customOption ? null : Number(s[0].school_id), school_name: s[0].school_name 
                                                    }
                                                }
                                            }}
                                            filterBy={(option, props) => {
                                                return option.school_name.toLowerCase().replace(/[^\w\']|_/g, "").indexOf(props.text.toLowerCase().replace(/[^\w\']|_/g, "")) !== -1;
                                            }}
                                            isInvalid={saveStudentValidated && !(data.student.school && !(isNullOrEmpty(data.student.school_id) && isNullOrEmpty(data.student.school.school_name)))}
                                            /*selected={selectedItems}*/
                                            options={schools}
                                            /*multiple*/
                                            /*minLength={3}*/
                                            /*style={{ width: "100%" }}*/
                                            inputProps={{ required: true }}
                                            ref={schoolLookUpRef}
                                        />
                                        {/*!(data.student.school && data.student.school.school_name && data.student.school.school_name !== "")*/}
                                        <button className="btn btn-secondary" type="button" onClick={clearSchoolLookup}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </button>
                                        <div className="invalid-feedback">
                                            School is required.
                                        </div>
                                    </div>
                                    <NewSchoolModal ref={childRef} onSchoolAdded={onSchoolAdded} associationCode={associationCode} />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            <Modal isOpen={modalRageWarning} toggle={toggleModalRageWarning} size="md" fade={true}>
                <ModalHeader toggle={toggleModalRageWarning}>
                    <FontAwesomeIcon icon={faTriangleExclamation} className="pe-2 text-warning" />
                    Warning
                </ModalHeader>
                <ModalBody className="">
                    <div className="row">
                        <div className="col-3 text-center">
                            <FontAwesomeIcon icon={faTriangleExclamation} className="display-1 text-warning" />
                        </div>
                        <div className="col-9">
                            <p className="">
                                Warning: The competitor has compete-down divisions selected.
                            </p>
                            <p className="">
                                A change in Rank, Date of Birth, Gender or Weight will require events to be selected again.
                            </p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModalRageWarning}>
                        Cancel
                    </Button>
                    {/*<Button color="primary" onClick={addEventsForCompetitor} disabled={!(eventsToAdd && eventsToAdd.length > 0)}>*/}
                    <Button color="primary" disabled={false} onClick={(e) => { toggleModalRageWarning(); setData({ ...data, events: null }); handleSaveStudent(e, true) }}>
                        Save Competitor
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );

});
export default NewCompetitor;
