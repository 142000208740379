import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserPen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';
import AddStudentCard from './AddStudentCard';
import StudentCard from './StudentCard';
import useContextTournament from '../useContextTournament';

export default function StudentCards(props) {
    const { profile } = props;
    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();

    const [students, setStudents] = useState([]);
    const [loadingStudents, setLoadingStudents] = useState(false);

    useEffect(() => {
        getData();
    }, [associationCode, tournamentId]);

    const getData = async () => {
        if (associationCode) {
            setLoadingStudents(true);
            //get the profile data
            await apiFetch.get(`/api/${associationCode}/profile/students?tournamentId=${tournamentId}`)
                .then(resp => {
                    setStudents(resp.data);
                })
                .finally(() => {
                    setLoadingStudents(false);
                });
        }
    };

    const profileStudentAdded = async () => {
        getData();
    };
    const removeProfileStudent = async (studentId) => {
        await apiFetch.delete('/api/profile/student?profileId=' + encodeURIComponent(profile.profileId) + '&studentId=' + encodeURIComponent(studentId))
            .then((resp) => {
                getData();
            })
            .catch((ex) => {
                console.error(ex);
            })
            .finally(() => {
            });
    };



    return (
        <div className="row d-flex g-3 pt-5">
            <div className="col-12">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                    <span className="">Competitors</span>
                </h4>
                <h6 className="d-flex justify-content-between align-items-center mb-1">
                    <span className="text-secondary">Add new or existing competitors to your profile</span>
                </h6>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3">
                {loadingStudents ?
                    <div className="col">
                        <div className="card h-100 align-items-center" style={{ cursor: "pointer" }}>
                            <div className="row g-0 text-center">
                                <div className="col mt-5">
                                    {/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                                    {/*d-md-block*/}
                                    <FontAwesomeIcon icon={faSpinner} spin className="display-1" />
                                    <h5 className="pt-2 py-5">Loading</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {students ? students.map((s, i) =>
                            <Fragment key={"student-" + s.studentId}>
                                <StudentCard
                                    {...props}
                                    student={s}
                                    actions={[
                                        //<button type="button" className="btn btn-secondary"
                                        //    style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={(e) => alert('addProfileStudent(e, s.studentId)')} >
                                        //    <FontAwesomeIcon icon={faPlus} className="pe-1" />
                                        //    Add To Profile
                                        //</button>,
                                        //<button type="button" className="btn btn-secondary"
                                        //    style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} >
                                        //    <FontAwesomeIcon icon={faUserPen} className="pe-1" />
                                        //    Edit
                                        //</button>,
                                    ]}
                                    showCloseButton
                                    closeAction={() => removeProfileStudent(s.studentId)}
                                />
                            </Fragment>
                        ) : null}
                        <AddStudentCard {...props} profileStudentAdded={profileStudentAdded} />
                    </>
                }



                {/*<div className="col" key={"student-" + s.studentId}>*/}
                {/*    <div className="card h-100 ">*/}
                {/*        <div className="row g-0">*/}
                {/*            <div className="col-md-4">*/}
                {/*                */}{/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                {/*                */}{/*d-md-block*/}
                {/*                <div className="col d-sm-none">*/}
                {/*                    <div className="hex mt-3 bg-red mr-4" style={{ width: "204px", height: "184px" }} >*/}
                {/*                        <div className="hex-background" style={{ top: "2px", left: "2px", width: "200px", height: "180px" }} >*/}
                {/*                            <img className="img img-fluid" style={{ position: "relative" }} src={s.imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col d-none d-sm-block"> */}{/*d-md-none*/}
                {/*                    <div className="hex mt-3 bg-red mr-4" style={{ width: "104px", height: "94px" }} >*/}
                {/*                        <div className="hex-background" style={{ top: "2px", left: "2px", width: "100px", height: "90px" }} >*/}
                {/*                            <img className="img img-fluid" style={{ position: "relative" }} src={s.imageUrl ?? 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-8">*/}
                {/*                <div className="card-body">*/}
                {/*                    <h5 className="card-title">{s.firstName} {s.lastName}</h5>*/}
                {/*                    <p className="card-text">*/}
                {/*                        {s.rankName}<br />*/}
                {/*                        Competitor Age: {s.competitionAge}*/}
                {/*                    </p>*/}
                {/*                    <hr className="mb-2" />*/}
                {/*                    <div className="col align-self-end text-truncate"><h5>{s.schoolName}</h5><h6>{s.studentCity}, {s.studentState}</h6></div>*/}
                {/*                </div>*/}
                {/*                <div className="card-footer border-0 bg-transparent">*/}
                {/*                    <p className="card-text text-end">*/}
                {/*                        <small className="text-body-secondary">*/}
                {/*                            <button type="button" className="btn btn-secondary"*/}
                {/*                                style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} >*/}
                {/*                                <FontAwesomeIcon icon={faUserPen} className="pe-1" />*/}
                {/*                                Edit*/}
                {/*                            </button>*/}
                {/*                        </small>*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="col">*/}
                {/*    <div className="card h-100 ">*/}
                {/*        <div className="row g-0">*/}
                {/*            <div className="col-md-4">*/}
                {/*                */}{/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                {/*                */}{/*d-md-block*/}
                {/*                <div className="col d-sm-none">*/}
                {/*                    <div className="hex mt-3 bg-red mr-4" style={{ width: "204px", height: "184px" }} >*/}
                {/*                        <div className="hex-background" style={{ top: "2px", left: "2px", width: "200px", height: "180px" }} >*/}
                {/*                            <img className="img img-fluid" style={{ position: "relative" }} src={!null ? 'https://ik.imagekit.io/sidekick/a8f10ac6-b7e7-4912-945b-444eeef76333?tr=small_profile' : 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col d-none d-sm-block"> */}{/*d-md-none*/}
                {/*                    <div className="hex mt-3 bg-red mr-4" style={{ width: "104px", height: "94px" }} >*/}
                {/*                        <div className="hex-background" style={{ top: "2px", left: "2px", width: "100px", height: "90px" }} >*/}
                {/*                            <img className="img img-fluid" style={{ position: "relative" }} src={!null ? 'https://ik.imagekit.io/sidekick/a8f10ac6-b7e7-4912-945b-444eeef76333?tr=small_profile' : 'https://ik.imagekit.io/sidekick/sidekick_default_competitor.png?tr=small_profile'} alt="" />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*            <div className="col-md-8">*/}
                {/*                <div className="card-body">*/}
                {/*                    <h5 className="card-title">Lucia Alonzo</h5>*/}
                {/*                    <p className="card-text">*/}
                {/*                        Yellow Belt<br />*/}
                {/*                        Competitor Age: 8*/}
                {/*                    </p>*/}
                {/*                    <hr className="mb-2" />*/}
                {/*                    <div className="col align-self-end text-truncate"><h5>Houston  asd f ads fasd f asdf asd fas d asdf a sd sd ad asd fas  sda asdf sda sdaf as ds adf asdf as asdfKarate Academy</h5><h6>Houston, TX</h6></div>*/}
                {/*                </div>*/}
                {/*                <div className="card-footer border-0 bg-transparent">*/}
                {/*                    <p className="card-text text-end">*/}
                {/*                        <small className="text-body-secondary">*/}
                {/*                            <button type="button" className="btn btn-secondary"*/}
                {/*                                style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} >*/}
                {/*                                <FontAwesomeIcon icon={faUserPen} className="pe-1" />*/}
                {/*                                Edit*/}
                {/*                            </button>*/}
                {/*                        </small>*/}
                {/*                    </p>*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="col">*/}
                {/*    <div className="card h-100 align-items-center" onClick={() => alert('click add')} style={{cursor: "pointer"} }>*/}
                {/*        <div className="row g-0 text-center">*/}
                {/*            <div className="col mt-5">*/}
                {/*                */}{/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                {/*                */}{/*d-md-block*/}
                {/*                <FontAwesomeIcon icon={faPlus} className="display-1" />*/}
                {/*                <h5 className="pt-2 py-5">Add Student</h5>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                    {/*<div className="col">*/}
                    {/*    <div className="card shadow-sm">*/}
                    {/*        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/00/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>*/}
                    {/*            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                <div className="btn-group">*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">View</button>*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button>*/}
                    {/*                </div>*/}
                    {/*                <small className="text-body-secondary">9 mins</small>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col">*/}
                    {/*    <div className="card shadow-sm">*/}
                    {/*        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>*/}
                    {/*            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                <div className="btn-group">*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">View</button>*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button>*/}
                    {/*                </div>*/}
                    {/*                <small className="text-body-secondary">9 mins</small>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col">*/}
                    {/*    <div className="card shadow-sm">*/}
                    {/*        <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>*/}
                    {/*            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                <div className="btn-group">*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">View</button>*/}
                    {/*                    <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button>*/}
                    {/*                </div>*/}
                    {/*                <small className="text-body-secondary">9 mins</small>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
            </div>
        </div>
    );

}
