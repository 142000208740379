import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faBuilding, faTriangleExclamation, faArrowLeft, faSchool, faPenToSquare, faRotateLeft, faXmark, faFloppyDisk, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import useContextTournament from '../useContextTournament';


export const NewSchoolModal = forwardRef((props, ref) => {

    const { tournamentId } = useContextTournament();
    const { createRecord, onSchoolAdded, associationCode } = props;
    const [schoolData, setSchoolData] = useState({
        school_id: null,
        school_name: "",
        address_id: null,
        association_cd: associationCode,
        address: {
            address_id: null,
            address_line_1: "",
            city: "",
            state_prov: "",
            postal_code: "",
            latitude: null,
            longitude: null,
            address_type: "",
            location_name: null,
            country_cd: null
        }
    });
    const [modalNewSchool, setModalNewSchool] = useState(false);
    const newSchoolForm = useRef(null);
    const [newSchoolValidated, setNewSchoolValidated] = useState(false);
    const [saving, setSaving] = useState(false);

    const toggleModalNewSchool = () => setModalNewSchool(!modalNewSchool);


    useImperativeHandle(ref, () => ({
        openNewSchool(school) {
            setNewSchoolValidated(false);
            setSaving(false);
            setSchoolData(school);
            //    school_id: null,
            //    school_name: school ? school.school_name : '',
            //    address_id: null,
            //    association_cd: "",
            //    address: {
            //        address_id: null,
            //        address_line_1: "",
            //        city: "",
            //        state_prov: "",
            //        postal_code: "",
            //        latitude: null,
            //        longitude: null,
            //        address_type: "",
            //        location_name: null,
            //        country_cd: null
            //    }
            //})
            toggleModalNewSchool();
        }
    }));


    const handleSaveSchool = async (event) => {
        let form = newSchoolForm.current;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setNewSchoolValidated(true);
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            setNewSchoolValidated(true);

            setSaving(true);

            if (createRecord) {
                const resp = await fetch(`/api/${tournamentId}/competitor/school/`, {
                    method: 'POST',
                    body: JSON.stringify(schoolData, (key, value) => typeof value === 'undefined' ? null : value),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const rdata = await resp.json();
            }

            setSaving(false);
            setNewSchoolValidated(false);

            if (onSchoolAdded)
                onSchoolAdded(schoolData);

            toggleModalNewSchool();

        }
    };
    const setAddressData = async (newData) => {
        setSchoolData({ ...schoolData, address: { ...schoolData.address, ...newData } });
    };
    const cancel = async (e) => {
        if (onSchoolAdded)
            onSchoolAdded(null)
        toggleModalNewSchool();
    };


    return (
        <>
            <Modal isOpen={modalNewSchool} toggle={toggleModalNewSchool} size="lg" className="modal-fullscreen-sm-down" fade={true}>
                <ModalHeader toggle={toggleModalNewSchool}>
                    <FontAwesomeIcon icon={faBuilding} className="pe-2" />
                    New School
                </ModalHeader>
                <ModalBody className="">
                    <form ref={newSchoolForm} noValidate className={`row g-3 needs-validation ${newSchoolValidated ? 'was-validated' : ''}`} onSubmit={handleSaveSchool}>
                        <div className="col-12">
                            <label htmlFor="name" className="form-label">School Name:</label>
                            <input type="text" name="firstName" value={schoolData.school_name} onChange={(e) => setSchoolData({ ...schoolData, school_name: e.target.value })} className="form-control" id="name" required />
                            <div className="invalid-feedback">
                                School name is required.
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="address" className="form-label">Address</label>
                            <input type="text" name="address" value={schoolData.address?.address_line_1} onChange={(e) => setAddressData({ ...schoolData.address, address_line_1: e.target.value })} className="form-control" id="address" />
                            {/*    <div className="invalid-feedback">*/}
                            {/*        Address is required.*/}
                            {/*    </div>*/}
                        </div>
                        <div className="col-4">
                            <label htmlFor="city" className="form-label">City</label>
                            <input type="text" name="lastName" value={schoolData.address?.city} onChange={(e) => setAddressData({ ...schoolData.address, city: e.target.value })} className="form-control" id="city" required />
                            <div className="invalid-feedback">
                                City is required.
                            </div>
                        </div>
                        <div className="col-4">
                            <label htmlFor="state" className="form-label">State</label>
                            <input type="text" name="state" value={schoolData.address?.state_prov} onChange={(e) => setAddressData({ ...schoolData.address, state_prov: e.target.value })} className="form-control" id="state" required />
                            <div className="invalid-feedback">
                                State is required.
                            </div>
                        </div>
                        <div className="col-4">
                            <label htmlFor="zip" className="form-label">Zip</label>
                            <input type="text" name="zip" value={schoolData.address?.postal_code} onChange={(e) => setAddressData({ ...schoolData.address, postal_code: e.target.value })} className="form-control" id="zip" />
                            {/*    <div className="invalid-feedback">*/}
                            {/*        Zip is required.*/}
                            {/*    </div>*/}
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={cancel}>
                        Cancel
                    </Button>
                    {/*<Button color="primary" onClick={addEventsForCompetitor} disabled={!(eventsToAdd && eventsToAdd.length > 0)}>*/}
                    <Button color="primary" disabled={false} onClick={(e) => { handleSaveSchool(e) }}>
                        {saving ? <FontAwesomeIcon icon={faSpinner} className="me-2" spin /> : null}
                        Save School
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );

});
export default NewSchoolModal;
