import React, { useState, useEffect, Fragment } from 'react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faStar, faMagnifyingGlass, faFloppyDisk, faPlus, faUserPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';
import moment from 'moment';
import StudentCard from './StudentCard';
import FindStudentUFAF from './FindStudentUFAF';
import FindStudentDefault from './FindStudentDefault';
import useContextTournament from '../useContextTournament';
import CreateStudentCard from './CreateStudentCard';

export default function AddStudentCard(props) {
    const { profile, profileStudentAdded } = props;
    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();

    const [students, setStudents] = useState([]);

    const [findStudentId, setFindStudentId] = useState(null);
    const [findDOB, setFindDOB] = useState(null);
    const [findingStudent, setFindingStudent] = useState(false);


    const [modalAdd, setModalAdd] = useState(false);

    const toggleModalAdd = () => setModalAdd(!modalAdd);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {

        //get the profile data
    //    await apiFetch.get('/api/profile/students')
    //        .then(resp => {
    //            setStudents(resp.data);
    //            console.log(resp.data);
    //        });
    };

    const findStudent = async (e) => {
        e.preventDefault();
        setFindingStudent(true);
        await apiFetch.get('/api/profile/students/find?associationId=' + encodeURIComponent(findStudentId) + '&dob=' + encodeURIComponent(moment(findDOB).format('YYYY-MM-DD')))
            .then(resp => {
                setStudents(resp.data);
                setFindingStudent(false);
            })
            .finally(() => {
                setFindingStudent(false);
            });

    };

    const addProfileStudent = async (e, studentId) => {
        await apiFetch.post('/api/profile/student', { profileId: profile.profileId, studentId: studentId, isPrimary: false })
            .then((resp) => {
                if (profileStudentAdded)
                    profileStudentAdded();

                setFindStudentId(null);
                setFindDOB(null);
                setStudents([]);
                toggleModalAdd();
            })
            .catch((ex) => {
            })
            .finally(() => {
            });
    };

    const foundStudents = (data) => {
        setStudents(data);
    };

    return (
        <>

            <div className="col">
                <div className="card h-100 align-items-center" onClick={toggleModalAdd} style={{ cursor: "pointer" }}>
                    <div className="row g-0 text-center">
                        <div className="col mt-5">
                            {/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                            {/*d-md-block*/}
                            <FontAwesomeIcon icon={faPlus} className="display-1" />
                            <h5 className="pt-2 py-5">Add Competitor</h5>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalAdd} toggle={toggleModalAdd} size="lg" fade>
                <ModalHeader toggle={toggleModalAdd}>
                    <FontAwesomeIcon icon={faUserPlus} className="pe-2" />Add Competitor
                </ModalHeader>
                <ModalBody className="">
                    {associationCode === 'UFAF' ?
                        <FindStudentUFAF foundStudents={foundStudents} />
                        :
                        <FindStudentDefault foundStudents={foundStudents} />
                    }
                    {students && students.length > 0 ?
                        <>
                            <hr />
                            {students.map((s, i) =>
                                <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <StudentCard student={s} actions={[
                                                <button type="button" className="btn btn-secondary"
                                                    style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={(e) => addProfileStudent(e, s.studentId)} >
                                                    <FontAwesomeIcon icon={faPlus} className="pe-1" />
                                                    Add To Profile
                                                </button>,
                                                //    <button type="button" className="btn btn-secondary"
                                                //        style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={() => alert('test')} >
                                                //        <FontAwesomeIcon icon={faPlus} className="pe-1" />
                                                //        Test
                                                //    </button>,
                                            ]} />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <CreateStudentCard onComplete={(student) => addProfileStudent(null, student.student_id)} />
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </>
                        :
                        <>
                            <hr />
                            <div className="col-12 col-lg-6">
                                <CreateStudentCard onComplete={(student) => addProfileStudent(null, student.student_id)} />
                            </div>
                        </>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModalAdd}>
                        Cancel
                    </Button>
                    {/*<Button color="primary" onClick={teamSelected} disabled={selectedTeam ? false : true}>*/}
                    {/*    Select Team*/}
                    {/*</Button>*/}
                </ModalFooter>
            </Modal>

        </>
    );

}
