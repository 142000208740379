import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import useApiFetch from '../../ApiFetch';

export default function ResetPassword() {

    const [searchParams] = useSearchParams();
    let code = searchParams.get('code');

    const apiFetch = useApiFetch();

    const [newPassword, setNewPassword] = useState({ code: code, password: '', confirmPassword: '' });
    const [invalidCode, setInvalidCode] = useState(false);
    const [expiredCode, setExpiredCode] = useState(false);
    const [checkingCode, setCheckingCode] = useState(true);
    const [passwordReset, setPasswordReset] = useState(null);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (code && code.length > 0)
            setCheckingCode(true);
            apiFetch.get('/api/auth/checkresetpasswordcode?code=' + encodeURIComponent(code))
                .then(resp => {
                    if (resp.data) {
                        if (resp.data.isExpired) {
                            setExpiredCode(true);
                        }
                    }
                    else {
                        setInvalidCode(true);
                    }
                    setCheckingCode(false);
                });

    }, []);

    const onNewProfileChange = (e) => {
        var targetVar = e.target.name;
        var targetVal = e.target.value;

        setNewPassword({ ...newPassword, [targetVar]: targetVal });
    };

    const profileExists = async () => {
        const response = await apiFetch.get('/api/profile/exists?email=' + encodeURIComponent(newPassword.code));
        const rdata = await response.data;
        return rdata;
    }
    const validate = () => {
        let returnValue = true;

        if (newPassword.password.length < 6)
            returnValue = false;
        if (newPassword.password !== newPassword.confirmPassword)
            returnValue = false;

        return returnValue;
    }

    const resetPassword = async (e) => {
        e.preventDefault();
        setResettingPassword(true);
        setError(null);
        if (validate() && !await profileExists()) {
            await apiFetch.post('/api/auth/resetpassword', newPassword)
                .then(response => {
                    setPasswordReset(true);
                })
                .catch(ex => {
                    setError(ex.message);
                    setPasswordReset(false);
                    console.log('Caught Error', ex)
                });
            //const pdata = presponse.data;
            //console.log('registerProfile response', pdata);


        }

        setResettingPassword(false);

    }


    return (
        <>
            <div className="container px-2 pb-4">
                {!passwordReset ?
                    <div className="row d-flex g-3 justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-9">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="">Reset Password</span>
                            </h4>
                            <h6 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-secondary">Set a new password below</span>
                            </h6>
                            {invalidCode
                                ?
                                <div className="alert alert-danger" role="alert">
                                    The code is invalid.<br />Please generate a new one.
                                </div>
                                :
                                null
                            }
                            {expiredCode
                                ?
                                <div className="alert alert-danger" role="alert">
                                    The code is expired.<br />Please generate a new one.
                                </div>
                                :
                                null
                            }
                            {checkingCode ?
                                <div className="card p-2">
                                    <h6 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-secondary"><FontAwesomeIcon icon={faSpinner} spin className="me-2" /></span>
                                    </h6>
                                </div>
                                :
                                null
                            }
                            {error
                                ?
                                <div className="alert alert-danger" role="alert">
                                    An error has occurred and the password could not be set.<br />
                                    Please try generating another code.
                                </div>
                                :
                                null
                            }
                            {!(invalidCode || expiredCode || checkingCode) ?
                                <form onSubmit={resetPassword} className="card p-2">
                                    <div className="row g-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" className={`form-control ${newPassword.password.length < 6 ? 'is-invalid' : 'is-valid'}`} id="password" onChange={onNewProfileChange} name="password" value={newPassword.password} />
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                            <input type="password" className={`form-control ${newPassword.password === newPassword.confirmPassword && newPassword.confirmPassword !== '' ? 'is-valid' : 'is-invalid'}`} id="confirmPassword" onChange={onNewProfileChange} name="confirmPassword" value={newPassword.confirmPassword} />
                                        </div>
                                        <div className="col-sm-12 pt-3">
                                            <button type="submit" className="w-100 btn btn-secondary btn-lg" disabled={!validate()} >
                                                {resettingPassword ?
                                                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                                    :
                                                    <FontAwesomeIcon icon={faKey} className="pe-2" />
                                                }
                                                Set New Password
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    <div className="row d-flex g-3 justify-content-center">
                        <div className="col-lg-6 col-md-9 col-sm-12">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="">Reset Password</span>
                            </h4>
                            <h6 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-secondary">Your new password has been set.</span>
                            </h6>
                            <h6 className="d-flex justify-content-between align-items-center mb-3">
                                <Link to="/login" className="link link-secondary">Login Here</Link>
                            </h6>
                        </div>
                    </div>
                }

            </div>
        </>
    );

}

