import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DashboardCard from "./DashboardCard";
import NewCompetitor from '../profile/NewCompetitor';
import NewCompetitorEvents from '../profile/NewCompetitorEvents';

export const NewCompetitorRegistration = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        open(data, step) {
            openNewCompetitor(data, step);
        }
    }));

    const { onComplete, allowEventSelection } = props;
    const [modal, setModal] = useState();
    const [newCompetitor, setNewCompetitor] = useState(null);
    const [step, setStep] = useState('competitor');


    const toggleModal = (open) => setModal(open === undefined ? !modal : open);

    const openNewCompetitor = (data, step) => {
        setNewCompetitor(data ?? null);
        setStep(step ?? 'competitor');
        toggleModal(true);
        //setNewCompetitorDetail(null);
    };

    const newCompetitorRef = useRef();
    const addEvents = (e) => {
        if (newCompetitorRef.current) {
            newCompetitorRef.current.save(e);
        }
    };
    const newCompetitorSaved = (competitor) => {
        if (!competitor.transId) {
            competitor.transId = crypto.randomUUID();
        }
        setNewCompetitor(competitor);
        setStep('events');
    };
    const newCompetitorEventSelect = (event) => {
        let events = newCompetitor.events;
        if (!events)
            events = [];
        let thisIx = events.findIndex(x => x.event_type_id === event.event_type_id);
        if (thisIx >= 0) {
            //it exists, so remove it
            events.splice(thisIx, 1);
            setNewCompetitor({ ...newCompetitor, events: [...events] });
        }
        else {
            events.push(event);
            setNewCompetitor({ ...newCompetitor, events: [...events] });
        }
    };
    const newCompetitorComplete = (e) => {
        setStep('events');
        if (onComplete)
            onComplete(newCompetitor);
        toggleModal();
    };
    const eventsGoBack = () => {
        setStep('competitor');
    };


    return (
        <>
            {/*<DashboardCard icon={faUserPlus} title="Add New Competitor" onClick={() => openNewCompetitor()} />*/}
            <Modal isOpen={modal} toggle={() => toggleModal()} size="xl" className="modal-fullscreen-lg-down" fade={true}>
                <ModalHeader toggle={() => toggleModal()}>
                    <FontAwesomeIcon icon={faUserPlus} className="pe-2" />Competitor Registration
                </ModalHeader>
                <ModalBody className="">
                    {step === 'competitor' ?
                        <NewCompetitor ref={newCompetitorRef} saved={newCompetitorSaved} competitorData={newCompetitor} />
                        : null}
                    {/*newCompetitor: {JSON.stringify(newCompetitor)}*/}
                    {step === 'events' ?
                        <NewCompetitorEvents competitorDetail={newCompetitor} editEvents={true} eventSelect={newCompetitorEventSelect} eventsSelected={[]} goBack={eventsGoBack} />
                        : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal()}>
                        Cancel
                    </Button>
                    {allowEventSelection ?
                        <>
                            {step === 'competitor' ?
                                <Button color="primary" onClick={addEvents} disabled={false}>
                                    Add Events
                                    <FontAwesomeIcon icon={faArrowRight} className="ps-2" />
                                </Button>
                                : null}
                            {/*newCompetitor: {JSON.stringify(newCompetitor)}*/}
                            {step === 'events' ?
                                <Button color="primary" onClick={newCompetitorComplete} disabled={false}>
                                    Save Competitor
                                    <FontAwesomeIcon icon={faArrowRight} className="ps-2" />
                                </Button>
                                : null}
                        </>
                        :
                        <Button color="primary" onClick={newCompetitorComplete} disabled={false}>
                            Save Competitor
                            <FontAwesomeIcon icon={faArrowRight} className="ps-2" />
                        </Button>
                    }
                </ModalFooter>
            </Modal>

        </>
    );
});
export default NewCompetitor;
