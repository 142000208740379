import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';

function ProfileInfo(props) {
    const apiFetch = useApiFetch();

    const [profileInfo, setProfileInfo] = useState({ firstName: '', lastName: '' });
    const [savingProfileInfo, setSavingProfileInfo] = useState(false);
    const [profileInfoSaveResult, setProfileInfoSaveResult] = useState(null);

    useEffect(() => {
        if (props.profileInfo) {
            setProfileInfo(props.profileInfo);
        }
    }, [props]);

    const saveProfileInfo = async (e) => {
        e.preventDefault();
        setSavingProfileInfo(true);

        await apiFetch.post('/api/profile/profileinfo', profileInfo)
            .then((resp) => {
                setProfileInfoSaveResult('Success');
                setTimeout(() => { setProfileInfoSaveResult(null); }, 5000);
                if (props.saved)
                    props.saved(resp.data);
            })
            .catch((ex) => {
                setProfileInfoSaveResult('Fail');
                setTimeout(() => { setProfileInfoSaveResult(null); }, 5000);
                setProfileInfo({
                    firstName: props.profileInfo.firstName,
                    lastName: props.profileInfo.lastName,
                });
            })
            .finally(() => {
                setSavingProfileInfo(false);
            });
    };

    return (
        <form onSubmit={saveProfileInfo} className="card">
            <div className="card-header">
                Personal Info
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label">First Name</label>
                        <input type="text" className={`form-control ${(profileInfo.firstName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="firstName" onChange={(e) => setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })} name="firstName" value={profileInfo.firstName ?? ''} />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input type="text" className={`form-control ${(profileInfo.lastName ?? '') !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={(e) => setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value })} name="lastName" value={profileInfo.lastName ?? ''} />
                    </div>
                </div>
            </div>
            <div className="card-footer text-body-secondary text-end">
                <button type="submit" className="btn btn-secondary btn-sm position-relative" disabled={(profileInfo.firstName ?? '') === '' || (profileInfo.lastName ?? '') === ''}>
                    <FontAwesomeIcon icon={savingProfileInfo ? faSpinner : faFloppyDisk} spin={savingProfileInfo} className="me-2" />
                    Save
                    {profileInfoSaveResult
                        ?
                        <span className={`position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ${profileInfoSaveResult === 'Success' ? "bg-success" : profileInfoSaveResult === 'Fail' ? "bg-danger" : ""}`}>
                            <span className="visually-hidden">Saved</span>
                        </span>
                        : null
                    }
                </button>
            </div>
        </form>
    );

}
export default ProfileInfo;
