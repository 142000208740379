import React, { Component } from 'react';
import { faHeart, faStar, faTrashCan, faPenToSquare  } from '@fortawesome/free-regular-svg-icons';
import { faCamera, faMagnifyingGlass, faArrowRight, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Html5QrcodeScanner } from 'html5-qrcode';
import HomeDashboardCard from "./HomeDashboardCard";
import { useNavigate, Link } from 'react-router-dom';
import useContextTournament from './useContextTournament';

export default function Home() {

    const { contextTournament, tournamentId, reloadContextTournament } = useContextTournament();

    const getEventDates = () => {
        if (contextTournament) {
            let returnText = null;
            if (contextTournament.tournament_end_date) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (contextTournament.tournament_end_date === contextTournament.tournament_start_date) {
                    returnText = moment(contextTournament.tournament_start_date).format('MMMM D, YYYY');
                }
                else {
                    //if the month is the same, show Month Start-End, otherwise, show Month Start - Month End
                    if (moment(contextTournament.tournament_start_date).month() === moment(contextTournament.tournament_end_date).month()) {
                        returnText = moment(contextTournament.tournament_start_date).format('MMMM') + ' ' + moment(contextTournament.tournament_start_date).format('D') + '-' + moment(contextTournament.tournament_end_date).format('D');
                        if (moment(contextTournament.tournament_start_date).year() === moment(contextTournament.tournament_end_date).year()) {
                            returnText += ', ' + moment(contextTournament.tournament_start_date).year();
                        }
                    }
                    else {
                        if (moment(contextTournament.tournament_start_date).year() === moment(contextTournament.tournament_end_date).year()) {
                            returnText = moment(contextTournament.tournament_start_date).format('MMMM D') + '-' + moment(contextTournament.tournament_end_date).format('MMMM D') + ', ' + moment(contextTournament.tournament_start_date).year();
                        }
                        else {
                            returnText = moment(contextTournament.tournament_start_date).format('MMMM D, YYYY') + ' - ' + moment(contextTournament.tournament_end_date).format('MMMM D, YYYY');
                        }
                    }
                }
            }
            else {
                returnText = moment(contextTournament.tournament_start_date).format('MMMM D, YYYY');
            }
            return returnText;
        }

    };
    return (
        <>
            <div className="container-xl px-2 pb-4">
                {contextTournament ?
                    <div className="p-5 bg-body-tertiary rounded-3 m-3">
                        <div className="row flex-lg-row-reverse align-items-center g-5 py-3">
                            <div className="col-12 col-sm-12 col-lg-6 text-center">
                                <img src={contextTournament.tournament_logo ?? 'https://open.sidekick.events/assets/images/associations/OPEN/logo-small.png'} className="d-block mx-lg-auto img-fluid rounded" width="700" loading="lazy" />
                            </div>
                            <div className="col-lg-6">
                                <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{contextTournament.tournament_name}</h1>
                                <p className="lead text-muted">{getEventDates()}</p>
                                <p className="lead">
                                    {contextTournament.location_name}<br />
                                    {contextTournament.city}, {contextTournament.state_prov}
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                    <Link to='/tournament' className="btn btn-danger btn-lg px-4 me-md-2" type="button">
                                        Register
                                        <FontAwesomeIcon icon={faArrowRight} className="ms-2 fs-6" />
                                    </Link>
                                    {contextTournament.detail_filename ?
                                        <Link to={`https://open.sidekick.events/assets/${contextTournament.detail_filename}`} className="btn btn-outline-secondary btn-lg px-4" type="button" target="_new">
                                            Details
                                        </Link>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row mb-3 dashboard justify-content-center text-center">
                        <h2>No tournament configured.</h2>
                        <Link to="/" className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover h3" onClick={reloadContextTournament}>Reload</Link>
                    </div>
                }
            </div>
        </>
    );
}
