import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function SendRegistrationConfirmation(props) {
    const { registrationId, disabled } = props;

    const [processing, setProcessing] = useState(false);
    const [result, setResult] = useState(null);

    //const authUsername = GetAuthUsername();

    useEffect(() => {
    }, []);
    const sendConfirmation = async (e) => {
        e.preventDefault();
        setProcessing(true);
        try {
            const response = await fetch('/api/pos/4001/sendregistrationconfirmation?registrationId=' + encodeURIComponent(registrationId));
            const rdata = await response.ok;
            if (rdata) {
                setResult('Success');
            }
            else {
                setResult('Fail');
            }
            setTimeout(() => { setResult(null); }, 5000);

        }
        catch (ex) {
            setResult('Fail');
            setTimeout(() => { setResult(null); }, 5000);
            console.log(ex);
        }
        setProcessing(false);
    };



    return (
        <button className="btn btn-primary position-relative" disabled={disabled} onClick={sendConfirmation}>
            <FontAwesomeIcon icon={processing ? faSpinner : faReceipt} spin={processing} className="me-2" />Send Confirmation
            <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${result && result === 'Success' ? "bg-success" : result && result === 'Fail' ? "bg-danger" : ""}`}>
                {result && result === 'Fail' ? <>
                    Error
                    <span className="visually-hidden">Error Sending</span>
                </>
                    : result && result === 'Success' ? <>
                        Success
                        <span className="visually-hidden">Sent successfully</span>
                    </>
                        :
                    null
                }
            </span>
        </button>
    );
}
