import React, { Component, useState, useEffect } from 'react';
import {
    Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import { faUser, faHeart, faStar, faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faUser as faUserSolid, faCamera, faMagnifyingGlass, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useApiFetch } from '../ApiFetch';
import useContextTournament from './useContextTournament';

export const NavMenu = () => {
    const signOut = useSignOut();
    const authUser = useAuthUser();
    const authHeader = useAuthHeader();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();


    const [collapsed, setCollapsed] = useState(true);

    const [profileStudents, setProfileStudents] = useState([]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }
    const handleLogin = () => {
        //instance.loginRedirect(loginRequest);
    }
    const handleLogout = (e) => {
        e.preventDefault();
        signOut()
        navigate('/');
    };

    useEffect(() => {
        if (isAuthenticated() && associationCode) {
            //get the students for the profile
            getStudents();
        }

    }, [authUser, associationCode, tournamentId]);

    const getStudents = async () => {
        //const response = await fetch('/api/profile/students', {
        //    headers: {
        //        'Authorization': authHeader,
        //    }
        //});
        //const rdata = await response.json();
        await apiFetch.get(`/api/${associationCode}/profile/students`)
            .then((resp) => setProfileStudents(resp.data));
    };

    return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" container>
                    <NavbarBrand tag={Link} to="/">
                    <img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" alt="Sidekick" height="50" />
                    <img className="ms-4" src="https://open.sidekick.events/assets/images/associations/OPEN/logo-small.png" alt="Open" height="50" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    {/*<ul className="navbar-nav flex-grow">*/}
                        <Nav navbar>
                            <NavItem>
                                <NavLink tag={Link} to="/">Home</NavLink>
                        </NavItem>
                        {isAuthenticated() ?
                            <>
                                <NavItem>
                                    <NavLink tag={Link} to="/tournament">Tournament Registration</NavLink>
                                </NavItem>
                            </>
                            : null }
                        {/*<div className="dropdown text-end">*/}
                        {/*    <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">*/}
                        {/*        <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />*/}
                        {/*    </a>*/}
                        {/*    <ul className="dropdown-menu text-small show" style={{position: "absolute",  inset: "0px auto auto 0px", margin: "0px", transform: "translate3d(-51.2px, 33.8286px, 0px)"}} data-popper-placement="bottom-start">*/}
                        {/*        <li><a className="dropdown-item" href="#">New project...</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Settings</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Profile</a></li>*/}
                        {/*        <li><hr className="dropdown-divider" /></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Sign out</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav caret>
                                {authUser ?
                                    <>
                                        <FontAwesomeIcon icon={faUserSolid} />
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon icon={faUser} />
                                    </>
                                }
                            </DropdownToggle>
                            <DropdownMenu flip tag="ul">
                                {authUser ?
                                    <>
                                        <DropdownItem text tag="li">Welcome{authUser.profile && authUser.profile.firstName ? ', ' + authUser.profile.firstName : '!' }</DropdownItem>
                                        <DropdownItem divider tag="li" />
                                        <DropdownItem tag={Link} to="/profile">Profile</DropdownItem>
                                        <DropdownItem divider tag="li" />
                                        {profileStudents && profileStudents.length > 0
                                            ?
                                            <>
                                                {profileStudents.map((s, i) => { return <DropdownItem tag="li" key={"nav-student-" + s.studentId} >{s.firstName} {s.lastName}</DropdownItem> })}
                                                <DropdownItem divider tag="li"/>
                                            </>
                                            :
                                            null
                                        }
                                        <DropdownItem tag="li" onClick={handleLogout}>Log Out</DropdownItem>
                                    </>
                                    :
                                    <>
                                        <DropdownItem tag={Link} to="/profile/register" >Register</DropdownItem>
                                        <DropdownItem divider tag="li" />
                                        <DropdownItem tag={Link} to="/login" >Log In</DropdownItem>
                                    </>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                            {/*<NavItem>*/}
                            {/*    <NavLink tag={Link} to="/counter">Counter</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                            {/*    <NavLink tag={Link} to="/fetch-data">Fetch data</NavLink>*/}
                            {/*</NavItem>*/}
                    </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
}
