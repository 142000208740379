import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { NavLink, Link } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faPenToSquare, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faUserPen, faFloppyDisk, faUser, faStar, faShirt, faPallet, faMagnifyingGlass, faSpinner, faRoadBarrier, faTrashCan, faUsersLine, faReceipt } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import ProfileInfo from './ProfileInfo';
import ProfileEmail from './ProfileEmail';
import ProfilePassword from './ProfilePassword';
import StudentCards from './StudentCards';

import useApiFetch from '../../ApiFetch';

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export default function Profile() {
    const apiFetch = useApiFetch();

    const navigate = useNavigate();

    const [profile, setProfile] = useState(null);

    const [profileInfo, setProfileInfo] = useState({ firstName: '', lastName: '' });
    const [profileEmail, setProfileEmail] = useState('');


    const [additionalProducts, setAdditionalProducts] = useState([]);

    const [modalCompetitorLookup, setModalCompetitorLookup] = useState(false);
    const [competitorDetail, setCompetitorDetail] = useState(null);
    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);
    const [eventsToAdd, setEventsToAdd] = useState([]);

    const [registrationPricing, setRegistrationPricing] = useState([]);
    const [spectatorPricing, setSpectatorPricing] = useState([]);

    const [competitorItems, setCompetitorItems] = useState([]);
    const [spectatorItems, setSpectatorItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);
    const [appliedPromoCode, setAppliedPromoCode] = useState(null);
    const [productGroupFilter, setProductGroupFilter] = useState(null);

    const [modalTransactionComplete, setModalTransactionComplete] = useState(false);
    const [transactionRegistration, setTransactionRegistration] = useState(null);

    const [cont, setCont] = useState(null);


    const toggleCompetitorLookup = (open) => setModalCompetitorLookup(open === undefined ? !modalCompetitorLookup : open);
    const toggleCompetitorDetail = (open) => {
        setModalCompetitorDetail(open === undefined ? !modalCompetitorDetail : open);
        //    setEventsToAdd([]);
    };
    const toggleModalTransactionComplete = (open) => setModalTransactionComplete(open === undefined ? !modalTransactionComplete : open);


    useEffect(() => {
        getData();
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [cont]);

    const getData = async () => {

        //get the profile data
        await apiFetch.get('/api/profile/')
            .then(resp => {
                setProfileInfo({
                    firstName: resp.data.firstName,
                    lastName: resp.data.lastName,
                });
                setProfileEmail(resp.data.email);
                setProfile(resp.data);
            });



    };
    const profileInfoSaved = async (data) => {
        setProfile(data);
        setProfileInfo({
            firstName: data.firstName,
            lastName: data.lastName,
        });

    };
    const profileEmailSaved = async (data) => {
        setProfile(data);
        setProfileEmail(data.email);

    };



    return (
        <>
            <div className="container-xl px-2 pb-4">
                <div className="row d-flex g-3">
                    <div className="col-12">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="">Welcome</span>
                        </h4>
                        <h6 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-secondary">Enter your profile details below</span>
                        </h6>
                        {/*<div className="alert alert-danger" role="alert">*/}
                        {/*    An error has occurred and the profile was not created.<br />*/}
                        {/*    Please try again later.*/}
                        {/*</div>*/}
                        <div className="row g-3">
                            <div className="col-sm-6 col-md-8">
                                <ProfileInfo profileInfo={profileInfo} saved={profileInfoSaved} />
                                <ProfileEmail email={profileEmail} saved={profileEmailSaved} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <ProfilePassword email={profileEmail} saved={profileEmailSaved} />
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="d-xl-none">XL</div>*/}
                {/*<div className="d-lg-none">LG</div>*/}
                {/*<div className="d-md-none">MD</div>*/}
                {/*<div className="d-sm-none">SM</div>*/}
                <StudentCards profile={profile} />
            </div>
        </>
    );

}

