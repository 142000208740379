import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmark, faStar, faMagnifyingGlass, faFloppyDisk, faPlus, faUserPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useApiFetch from '../../ApiFetch';
import moment from 'moment';
import StudentCard from './StudentCard';
import FindStudentUFAF from './FindStudentUFAF';
import FindStudentDefault from './FindStudentDefault';
import useContextTournament from '../useContextTournament';
import NewCompetitor from './NewCompetitor';
import NewCompetitorEvents from './NewCompetitorEvents';

export default function CreateStudentCard(props) {
    const apiFetch = useApiFetch();


    const { onComplete } = props;
    const [modal, setModal] = useState();
    const [newCompetitor, setNewCompetitor] = useState(null);
    const [step, setStep] = useState('competitor');

    const newCompetitorRef = useRef();

    const { associationCode, tournamentId } = useContextTournament();

    const [students, setStudents] = useState([]);

    const [findStudentId, setFindStudentId] = useState(null);
    const [findDOB, setFindDOB] = useState(null);
    const [findingStudent, setFindingStudent] = useState(false);


    const [modalAdd, setModalAdd] = useState(false);

    const toggleModal = (open) => setModal(open === undefined ? !modal : open);

    const toggleModalAdd = () => setModalAdd(!modalAdd);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {

        //get the profile data
    //    await apiFetch.get('/api/profile/students')
    //        .then(resp => {
    //            setStudents(resp.data);
    //            console.log(resp.data);
    //        });
    };

    const foundStudents = (data) => {
        setStudents(data);
    };


    const openNewCompetitor = (data, step) => {
        setNewCompetitor(data ?? null);
        setStep(step ?? 'competitor');
        toggleModal(true);
        //setNewCompetitorDetail(null);
    };
    const newCompetitorSaved = (competitor) => {
        if (onComplete)
            onComplete(competitor);
        toggleModal();
        if (!competitor.transId) {
            competitor.transId = crypto.randomUUID();
        }
    //    setNewCompetitor(competitor);
    //    setStep('events');
    };
    const newCompetitorComplete = (e) => {
        if (onComplete)
            onComplete(newCompetitor);
        toggleModal();
    };
    const addEvents = (e) => {
        if (newCompetitorRef.current) {
            newCompetitorRef.current.save(e);
        }
    };
    const saveCompetitor = (e) => {
        if (newCompetitorRef.current) {
            newCompetitorRef.current.save(e);
        }
    };

    return (
        <>

            <div className="card h-100 align-items-center" onClick={openNewCompetitor} style={{ cursor: "pointer" }}>
                <div className="row g-0 text-center">
                    <div className="card-body dash-button">
                        <FontAwesomeIcon icon={faUserPlus} className="" />
                        <h5 className="card-title">Create New Competitor</h5>
                    </div>

                {/*    <div className="col mt-5">*/}
                {/*        */}{/*<img src="https://www.sidekick.events/assets/images/sidekick_logo_right.png" className="img-fluid rounded-start" alt="..." />*/}

                {/*        */}{/*d-md-block*/}
                {/*        <FontAwesomeIcon icon={faUserPlus} className="display-1" />*/}
                {/*        <h5 className="pt-2 py-5">Create New Competitor</h5>*/}
                {/*    </div>*/}
                </div>
            </div>

            <Modal isOpen={modal} toggle={() => toggleModal()} size="xl" className="modal-fullscreen-lg-down" fade={true}>
                <ModalHeader toggle={() => toggleModal()}>
                    <FontAwesomeIcon icon={faUserPlus} className="pe-2" />New Competitor
                </ModalHeader>
                <ModalBody className="">
                    {step === 'competitor' ?
                        <NewCompetitor ref={newCompetitorRef} saved={newCompetitorSaved} /*competitorData={newCompetitor}*/ createNew />
                        : null}
                    {/*newCompetitor: {JSON.stringify(newCompetitor)}*/}
                {/*    {step === 'events' ?*/}
                {/*        <NewCompetitorEvents competitorDetail={newCompetitor} editEvents={true} eventSelect={newCompetitorEventSelect} eventsSelected={[]} goBack={eventsGoBack} />*/}
                {/*        : null}*/}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal()}>
                        Cancel
                    </Button>
                {/*    {step === 'competitor' ?*/}
                        {/*<Button color="primary" onClick={addEvents} disabled={false}>*/}
                        {/*    Add Events*/}
                        {/*    <FontAwesomeIcon icon={faArrowRight} className="ps-2" />*/}
                        {/*</Button>*/}
                {/*        : null}*/}
                {/*    */}{/*newCompetitor: {JSON.stringify(newCompetitor)}*/}
                {/*    {step === 'events' ?*/}
                        <Button color="primary" onClick={saveCompetitor} disabled={false}>
                            Save Competitor
                            <FontAwesomeIcon icon={faArrowRight} className="ps-2" />
                        </Button>
                {/*        : null}*/}
                </ModalFooter>
            </Modal>

        </>
    );

}
