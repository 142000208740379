import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Complete(props) {
    const [messageBody, setMessageBody] = useState('');
    const stripePromise = loadStripe('pk_test_51O9dP4JjV5MFiluL1mtOOpDUvmDUfFcAxq4WG4hFN5L3n4TcH1kpn5mdmr2TzWz2EJaPTi1chGqarl6JBN7fCPhV00lxbdPAs3');

    useEffect(() => {
        console.log(stripePromise);
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
            console.log(error, paymentIntent);
            setMessageBody(error ? `> ${error.message}` : (
                <>&gt; Payment {paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
            ));
        });
    }, []);

    return (
        <>
            <h1>Thank you!</h1>
            <a href="/">home</a>
            <div id="messages" role="alert" >asdf{messageBody} </div>
        </>
    );
}

export default Complete;