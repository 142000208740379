import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRotateLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function ProfileSearchTransaction(props) {
    const [profile, setProfile] = useState(null);
    const [newProfile, setNewProfile] = useState({ email: '', firstName: '', lastName: '' });
    const [applied, setApplied] = useState(false);

    const [searching, setSearching] = useState(false);

    useEffect(() => {
    }, []);
    const profileSearch = async (e) => {
        if (e)
            e.preventDefault();

        setSearching(true);
        setApplied(false);
        try {
            const response = await fetch('/api/pos/4001/profilesearch?email=' + encodeURIComponent(newProfile.email));
            const rdata = await response.json();
            if (rdata) {
                setProfile(rdata);
                setNewProfile({ email: '', firstName: '', lastName: '' });
                if (props.applyProfile) {
                    props.applyProfile(rdata);
                }
            }
        }
        catch {
        //    setProfile(null);
        //    if (props.applyProfile) {
        //        props.applyProfile(null);
        //    }
        }
        setApplied(true);
        setSearching(false);
    };
    const clearProfile = (e) => {
        e.preventDefault();
        setProfile(null);
        setApplied(false);
        if (props.applyProfile) {
            props.applyProfile(null);
        }
    };

    const emailBlur = () => {
        if (!(applied && profile) && isEmail(newProfile.email)) {
            setTimeout(profileSearch, 200);
        }
    };

    const onNewProfileChange = (e) => {
        var targetVar = e.target.name;
        var targetVal = e.target.value;

        setNewProfile({ ...newProfile, [targetVar]: targetVal });
        if (props.applyProfile) {
            props.applyProfile({ ...newProfile, [targetVar]: targetVal });
        }
    };

    return (
        <>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-secondary">Profile</span>
                <span className="badge bg-secondary rounded-pill"></span>
            </h4>
            <form onSubmit={profileSearch} className="card p-2">
                {applied && profile ?
                    <>
                        <div className="row d-flex g-3 px-2">
                            <div className="col-12">
                                <h6>{profile.email} <FontAwesomeIcon icon={searching ? faSpinner : faRotateLeft} spin={searching} className="" title="Change Profile" onClick={clearProfile} style={{ cursor: "pointer" }} /></h6>
                                <h6>{profile.firstName} {profile.lastName}</h6>
                                <h6>Profile #{profile.profileId}</h6>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row g-3 pb-2">
                            <div className="col-sm-12">
                                <label htmlFor="txtEmail" className="form-label">Email Address</label>
                                <div className="input-group pb-2">
                                    <input id="txtEmail" type="email" className={`form-control ${profile || (newProfile.email === '' || isEmail(newProfile.email)) ? '' : 'is-invalid'}`} value={profile ? profile.email_address : newProfile.email} onBlur={emailBlur} onChange={onNewProfileChange} name="email" readOnly={applied && profile} />
                                    {applied && profile ?
                                        <button type="button" title="Change Profile" className="btn btn-secondary" onClick={clearProfile}><FontAwesomeIcon icon={searching ? faSpinner : faRotateLeft} spin={searching} className="" /></button>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row g-3">
                            <div className="col-sm-6">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" className="form-control" id="firstName" onChange={onNewProfileChange} name="firstName" value={applied && profile ? profile.firstName : newProfile.firstName} readOnly={applied && profile} />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id="lastName" onChange={onNewProfileChange} name="lastName" value={applied && profile ? profile.lastName : newProfile.lastName} readOnly={applied && profile} />
                            </div>
                        </div>
                    </>
                }
            </form>
            <hr className="my-4" />
        </>
    );

}
export default ProfileSearchTransaction;
