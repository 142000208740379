import React, { useState, useEffect, useCallback } from 'react';
import { getContextTournament } from '../contextTournament';

function useContextTournament() {

    const [tournament, setTournament] = useState(getContextTournament());
    const [tournamentId, setTournamentId] = useState(null);
    const [associationCode, setAssociationCode] = useState(null);

    function reloadContextTournament() {
        sessionStorage.removeItem('contextTournament');
        setTournament(getContextTournament());
    }
    function getAssociationCode() {
        const contextTournament = getContextTournament();
        return contextTournament?.association_cd;
    }
    function getTournamentId() {
        const contextTournament = getContextTournament();
        return contextTournament?.tournament_id;
    }

    useEffect(() => {
        setTournamentId(tournament?.tournament_id);
        setAssociationCode(tournament?.association_cd);
    }, []);


    return {
        contextTournament: tournament,
        tournamentId,
        associationCode,
        reloadContextTournament,
        getAssociationCode,
        getTournamentId
    };
}
export default useContextTournament;