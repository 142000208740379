import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useApiFetch from '../../ApiFetch';

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export default function Register() {
    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate();
    const apiFetch = useApiFetch();

    const [newProfile, setNewProfile] = useState({ email: '', firstName: '', lastName: '', phone: '', password: '', confirmPassword: '' });
    const [searching, setSearching] = useState(false);
    const [profileFound, setProfileFound] = useState(false);
    const [creatingProfile, setCreatingProfile] = useState(false);
    const [error, setError] = useState(null);
    const [profileCreated, setProfileCreated] = useState(null);

    useEffect(() => {

        if (isAuthenticated())
            navigate('/');

    }, []);

    const onNewProfileChange = (e) => {
        var targetVar = e.target.name;
        var targetVal = e.target.value;

        if (targetVar === 'email') {
            setProfileFound(false);
        }
        setNewProfile({ ...newProfile, [targetVar]: targetVal });
    };
    const emailBlur = () => {
        if (isEmail(newProfile.email)) {
            setTimeout(profileSearch, 100);
        }
        else {
            setProfileFound(false);
        }
    };
    const profileSearch = async (e) => {
        if (e)
            e.preventDefault();

        setProfileFound(false);
        setSearching(true);
        //setApplied(false);
        try {
            if (await profileExists()) {
                setProfileFound(true);
            }
        }
        catch {
            //    setProfile(null);
            //    if (props.applyProfile) {
            //        props.applyProfile(null);
            //    }
        }
        //setApplied(true);
        setSearching(false);
    };

    const profileExists = async () => {
        const response = await apiFetch.get('/api/profile/exists?email=' + encodeURIComponent(newProfile.email));
        const rdata = await response.data;
        return rdata;
    }
    const validate = () => {
        let returnValue = true;

        if (newProfile.firstName === '')
            returnValue = false;
        if (newProfile.lastName === '')
            returnValue = false;
        if (!isEmail(newProfile.email) || profileFound)
            returnValue = false;
        if (newProfile.password.length < 6)
            returnValue = false;
        if (newProfile.password !== newProfile.confirmPassword)
            returnValue = false;
        if (profileFound || searching)
            returnValue = false;

        return returnValue;
    }

    const registerProfile = async (e) => {
        e.preventDefault();
        setCreatingProfile(true);
        setError(null);
        if (validate() && !await profileExists()) {

            //const presponse = await fetch('/api/profile/register', {
            //    method: 'POST',
            //    body: JSON.stringify(newProfile, (key, value) => typeof value === 'undefined' ? null : value),
            //    headers: {
            //        'Content-Type': 'application/json'
            //    },
            //}).catch(ex => {
            //    console.log('Caught Error')
            //});
            //console.log('registerProfile response', presponse.data);

            const presponse = await apiFetch.post('/api/profile/register', newProfile)
                .then(response => {
                    setProfileCreated(response.data);
                })
                .catch(ex => {
                    setError(ex.message);
                    console.log('Caught Error', ex)
                });
            //const pdata = presponse.data;
            //console.log('registerProfile response', pdata);


        }

        setCreatingProfile(false);

    }


    return (
        <>
            <div className="container px-2 pb-4">
                {!profileCreated ?
                <div className="row d-flex g-3 justify-content-center">
                    <div className="col-lg-6 col-md-9 col-sm-12">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="">Sidekick Profile</span>
                        </h4>
                        <h6 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-secondary">Enter your profile details below</span>
                        </h6>
                        {error
                            ?
                                <div className="alert alert-danger" role="alert">
                                An error has occurred and the profile was not created.<br />
                                Please try again later.
                            </div>
                            :
                            null
                        }
                        <form onSubmit={registerProfile} className="card p-2">
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input type="text" className={`form-control ${newProfile.firstName !== '' ? 'is-valid' : 'is-invalid'}`} id="firstName" onChange={onNewProfileChange} name="firstName" value={newProfile.firstName} />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input type="text" className={`form-control ${newProfile.lastName !== '' ? 'is-valid' : 'is-invalid'}`} id="lastName" onChange={onNewProfileChange} name="lastName" value={newProfile.lastName} />
                                </div>
                                {/*<div className="col-sm-6">*/}
                                {/*    <label htmlFor="phone" className="form-label">Phone</label>*/}
                                {/*    <input type="phone" className={`form-control ${validated ? (newProfile.phone !== '' ? 'is-valid' : 'is-invalid') : ''}`} id="phone" onChange={onNewProfileChange} name="phone" value={newProfile.phone} />*/}
                                {/*    */}{/*<PatternFormat value={""} className="form-control" format="+# (###) ###-####" />*/}
                                {/*    */}{/*<PatternFormat value={""} className="form-control text-end" format="+##" />*/}
                                {/*    <PhoneInput*/}
                                {/*        country={'us'}*/}
                                {/*        value={newProfile.phone}*/}
                                {/*        onChange={phone => console.log(phone)}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="col-sm-12">
                                    <label htmlFor="email" className="form-label">Email</label>
                                        <div className="input-group">
                                        <input type="email" className={`form-control ${profileFound ? 'is-invalid' : isEmail(newProfile.email) ? 'is-valid' : 'is-invalid'}`} id="email" onBlur={emailBlur} onChange={onNewProfileChange} name="email" value={newProfile.email} />
                                        {searching ?
                                                <span className="input-group-text bg-transparent" id="basic-addon2"><FontAwesomeIcon icon={faSpinner} spin className="" /></span>
                                            :
                                            null}
                                    </div>
                                    {profileFound
                                        ?
                                        <div className="col-sm-12 mt-3">
                                            <ul className="list-group mb-3">
                                                <li className="list-group-item lh-sm text-bg-tertiary text-center">
                                                    A profile for <b>{newProfile ? newProfile.email ?? '' : ''}</b> already exists.<br />
{/*                                                    <Link className="btn btn-secondary mt-3" to={`/login?email=${newProfile ? newProfile.email ?? '' : ''}`}>Login Here</Link>*/}
                                                    <Link className="btn btn-secondary mt-3" to={`/login`}>Login Here</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className={`form-control ${newProfile.password.length < 6 ? 'is-invalid' : 'is-valid'}`} id="password" onChange={onNewProfileChange} name="password" value={newProfile.password} />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                    <input type="password" className={`form-control ${newProfile.password === newProfile.confirmPassword && newProfile.confirmPassword !== '' ? 'is-valid' : 'is-invalid'}`} id="confirmPassword" onChange={onNewProfileChange} name="confirmPassword" value={newProfile.confirmPassword} />
                                </div>
                                <div className="col-sm-12 pt-3">
                                    <button type="submit" className="w-100 btn btn-secondary btn-lg" disabled={!validate()} >
                                        {creatingProfile ?
                                            <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                            :
                                            <FontAwesomeIcon icon={faUser} className="pe-2" />
                                        }
                                        Register
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className="row d-flex g-3 justify-content-center">
                    <div className="col-lg-6 col-md-9 col-sm-12">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="">Sidekick Profile</span>
                        </h4>
                        <h6 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-secondary">Welcome! Your profile has been created and ready.</span>
                        </h6>
                        <h6 className="d-flex justify-content-between align-items-center mb-3">
                                <Link to="/login" className="link link-secondary">Login Here</Link>
                        </h6>
                    </div>
                </div>
                }
            </div>
        </>
    );

}

