import React, { useCallback } from 'react';
import useContextTournament from './useContextTournament';
export function useLookUpData() {

    const { associationCode, tournamentId, getAssociationCode, getTournamentId } = useContextTournament();

    const getLookUpData = async (key, apiUrl, refresh) => {
        if (refresh) {
            sessionStorage.removeItem(key);
        }
        const stored = sessionStorage.getItem(key);
        if (!stored) {
            const response = await fetch(apiUrl);
            const rdata = await response.json();

            if (rdata) {
                sessionStorage.setItem(key, JSON.stringify(rdata))
            }
            return rdata;
        }
        else {
            return JSON.parse(stored);
        }
    };

    const getRanks = useCallback(async (refresh) => {
        const obj = await getLookUpData('lookUp:ranks', '/api/' + encodeURIComponent(tournamentId ?? getTournamentId()) + '/lookup/ranks', refresh);
        return obj;
    }, [tournamentId]);
    const getSchools = useCallback(async (refresh) => {
        const obj = await getLookUpData('lookUp:schools', '/api/' + encodeURIComponent(associationCode ?? getAssociationCode()) + '/lookup/schools', refresh);
        return obj;
    }, [associationCode]);


    return {
        getRanks,
        getSchools
    };
}