import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { NavLink, Link } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faPenToSquare, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faUserPen, faUser, faStar, faShirt, faPallet, faMagnifyingGlass, faSpinner, faRoadBarrier, faTrashCan, faUsersLine, faReceipt } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import DashboardCard from "./DashboardCard";
import { NumericFormat } from 'react-number-format';
//import SearchCompetitorWithResults from '../competitors/SearchCompetitorWithResults';
//import CompetitorDetail from './CompetitorDetail';
import PromoCode from './PromoCode';
import ProfileSearchTransaction from './ProfileSearchTransaction';
import Payment from './Payment';
import SendRegistrationConfirmation from './SendRegistrationConfirmation';
import { useApiFetch } from '../../ApiFetch';
import StudentCard from '../profile/StudentCard';
import CompetitorCard from './CompetitorCard';
import useContextTournament from '../useContextTournament';
import StudentCardImageHex from '../profile/StudentCardImageHex';
import NewCompetitorDashboardCard from './NewCompetitorDashboardCard';
import CreateStudentCard from '../profile/CreateStudentCard';
import { NewCompetitorRegistration } from './NewCompetitorRegistration';


const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function POS(props) {
    const apiFetch = useApiFetch();
    const { associationCode, tournamentId } = useContextTournament();

    const newCompetitorDashboardCardRef = useRef(null);
    const newCompetitorRegistrationRef = useRef(null);


    const [competitors, setCompetitors] = useState([]);

    const [students, setStudents] = useState([]);
    const [loadingStudents, setLoadingStudents] = useState(false);


    const navigate = useNavigate();
    const [additionalProducts, setAdditionalProducts] = useState([]);

    const [modalCompetitorLookup, setModalCompetitorLookup] = useState(false);
    const [competitorDetail, setCompetitorDetail] = useState(null);
    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);
    const [eventsToAdd, setEventsToAdd] = useState([]);

    const [registrationPricing, setRegistrationPricing] = useState([]);
    const [spectatorPricing, setSpectatorPricing] = useState([]);

    const [profile, setProfile] = useState(null);
    const [newCompetitorItems, setNewCompetitorItems] = useState([]);
    const [editNewCompetitor, setEditNewCompetitor] = useState([]);
    const [competitorItems, setCompetitorItems] = useState([]);
    const [spectatorItems, setSpectatorItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);
    const [appliedPromoCode, setAppliedPromoCode] = useState(null);
    const [productGroupFilter, setProductGroupFilter] = useState(null);

    const [liabilityAccept, setLiabilityAccept] = useState(false);

    const [modalTransactionComplete, setModalTransactionComplete] = useState(false);
    const [transactionRegistration, setTransactionRegistration] = useState(null);
    const [completedRegistrationData, setCompletedRegistrationData] = useState(null);

    const [cont, setCont] = useState(null);


    const toggleCompetitorLookup = (open) => setModalCompetitorLookup(open === undefined ? !modalCompetitorLookup : open);
    const toggleCompetitorDetail = (open) => {
        setModalCompetitorDetail(open === undefined ? !modalCompetitorDetail : open);
    //    setEventsToAdd([]);
    };
    const toggleModalTransactionComplete = (open) => setModalTransactionComplete(open === undefined ? !modalTransactionComplete : open);
    

    useEffect(() => {
        //getData();
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [cont]);
    useEffect(() => {
        getData();
    }, [associationCode, tournamentId])

    const getData = async () => {

        await apiFetch.get('/api/profile/')
            .then(resp => {
                setProfile(resp.data);
            });

        setLoadingStudents(true);
        let compData = [];
        if (tournamentId) {
            await apiFetch.get(`/api/profile/competitors?tournamentId=${tournamentId}`)
                .then(resp => {
                    compData = resp.data;
                    setCompetitors(resp.data);
                })
                .finally(() => {
                    setLoadingStudents(false);
                });

        }

        if (associationCode) {
            //get the profile data
            await loadStudents();

            const response = await apiFetch.get(`/api/pos/${tournamentId}/additionalproducts`);
            const rdata = await response.data;
            //const response = await fetch('/api/pos/4001/additionalproducts');
            //const rdata = await response.json();
            setAdditionalProducts(rdata);

            const presponse = await fetch(`/api/pos/${tournamentId}/registrationpricing?date=` + encodeURIComponent(moment().format('YYYY-MM-DD')))
            const pdata = await presponse.json();
            setRegistrationPricing(pdata);

            const sresponse = await fetch(`/api/pos/${tournamentId}/spectatorpricing?date=` + encodeURIComponent(moment().format('YYYY-MM-DD')))
            const sdata = await sresponse.json();
            setSpectatorPricing(sdata);
        }
    }
    const loadStudents = async () => {
        setLoadingStudents(true);
        await apiFetch.get(`/api/${associationCode}/profile/students`)
            .then(resp => {
                let students = resp.data;
                //students = students.filter(s => !compData.map(x => x.studentId).includes(s.studentId));
                setStudents(students);
            })
            .finally(() => {
                setLoadingStudents(false);
            });
    };
    const openCompetitorLookup = row => {
        toggleCompetitorLookup(true);
        setCompetitorDetail(row);
    }
    const getCardIcon = (priceName) => {
        switch (priceName.toLowerCase()) {
            case "concrete":
                return faPallet;
                break;
            case "boards":
                return faRoadBarrier;
                break;
            case "tshirt":
                return faShirt;
                break;
            default:
                return null;
                break;
        }
    }



    const addSpectatorItem = (item) => {
        let clone = [...spectatorItems];

        let thisItemIndex = clone.findIndex(i => i.pass_type === item.pass_type);
        if (thisItemIndex >= 0) {
            let thisItem = clone[thisItemIndex];
            thisItem.quantity += 1
            clone[thisItemIndex] = thisItem;
            setSpectatorItems([...clone]);
        }
        else {
            let thisItem = { ...item, quantity: 1 };
            setSpectatorItems((prevItem) => [
                ...prevItem,
                thisItem,
            ]);
        }
    }
    const addAdditionalItem = (prd) => {

        let clone = [...additionalItems];

        let thisItemIndex = clone.findIndex(i => i.additional_price_id === prd.additional_price_id);
        if (thisItemIndex >= 0) {
            let thisItem = clone[thisItemIndex];
            thisItem.quantity += 1
            clone[thisItemIndex] = thisItem;
            setAdditionalItems([...clone]);
        }
        else {
            let thisItem = { ...prd, quantity: 1 };
            setAdditionalItems((prevItem) => [
                ...prevItem,
                thisItem,
            ]);
        }

    };
    const handleSpectatorQuantityChange = (ix, values, sourceInfo) => {
        if (sourceInfo.source !== 'event')
            return;

        let clone = [...spectatorItems];
        let item = clone[ix];
        item.quantity = values.floatValue;
        clone[ix] = item;
        setSpectatorItems([...clone]);

    };
    const handleAdditionalQuantityChange = (ix, values, sourceInfo) => {
        if (sourceInfo.source !== 'event')
            return;

        let clone = [...additionalItems];
        let item = clone[ix];
        item.quantity = values.floatValue;
        clone[ix] = item;
        setAdditionalItems([...clone]);

    };
    const handleQuantityFocus = (e) => {
        e.target.select();
    };

    const getAddtionalProductsGroups = () => {
        let groups = [];
        if (additionalProducts) {
            groups = Array.from(new Set(additionalProducts.map(e => e.price_name)))
                .map(id => {
                    return {
                        price_name: id
                    }
                });
        }
        return groups;
    };
    const getAdditionalProducts = () => {
        let prods = []
        if (productGroupFilter && additionalProducts) {
            return additionalProducts.filter(p => p.price_name === productGroupFilter);
        }
        return prods;
    };
    const getItemsTotal = () => {
        let total = 0;
        total = getItemsSubTotal();
        if (appliedPromoCode && !appliedPromoCode.is_expired && !appliedPromoCode.is_exceeded && appliedPromoCode.discount_amount) {
            if (appliedPromoCode.discount_type === 'AMT') {
                total += -appliedPromoCode.discount_amount;
            }
            else if (appliedPromoCode.discount_type === 'PCT') {
                total = (1 - appliedPromoCode.discount_amount) * total;
            }
        }
        return (total < 0) ? 0 : total;
    };
    const getItemsSubTotal = () => {
        let total = 0;
        if (newCompetitorItems) {
            newCompetitorItems.forEach(c => { total += getNewCompetitorItemTotal(c) });
        }
        if (competitorItems) {
            competitorItems.forEach(c => { total += getCompetitorItemTotal(c) });
        }
        if (spectatorItems) {
            spectatorItems.forEach(i => { total += i.price * i.quantity });
        }
        if (additionalItems) {
            additionalItems.forEach(i => { total += i.price_amount * i.quantity });
        }
        return total;
    };
    const getDiscountAmount = (promo) => {
        if (promo && !promo.is_expired && !promo.is_exceeded && promo.discount_amount) {
            if (promo.discount_type === 'AMT') {
                return -appliedPromoCode.discount_amount;
            }
            else if (promo.discount_type === 'PCT') {
                return -promo.discount_amount * getItemsSubTotal();
            }
        }
    }

    const getItemsCount = () => {
        let count = 0;
        if (newCompetitorItems) {
            newCompetitorItems.forEach(c => { count += c.events ? c.events.length : 0 });
        }
        if (competitorItems) {
            competitorItems.forEach(c => { count += c.events ? c.events.length : 0 });
        }
        if (spectatorItems) {
            spectatorItems.forEach(i => { count += i.quantity });
        }
        if (additionalItems) {
            additionalItems.forEach(i => { count += i.quantity });
        }
        return count;
    };
    const removeNewCompetitorItem = (index) => {
        let clone = [...newCompetitorItems];
        clone.splice(index, 1);
        setNewCompetitorItems([...clone]);
    }
    const removeCompetitorItem = (index) => {
        let clone = [...competitorItems];
        clone.splice(index, 1);
        setCompetitorItems([...clone]);
    }
    const removeSpectatorItem = (index) => {
        let clone = [...spectatorItems];
        clone.splice(index, 1);
        setSpectatorItems([...clone]);
    }
    const removeAdditionalItem = (index) => {
        let clone = [...additionalItems];
        clone.splice(index, 1);
        setAdditionalItems([...clone]);
    }
    const searchItemClicked = (row) => {
        if (competitorItems) {
            
            let existingCompetitor = competitorItems.find(comp => comp.competitor_id === row.competitor_id);
            if (existingCompetitor) {
                editCompetitorItem(existingCompetitor);
            }
            else {
                setCompetitorDetail(row);
                setEventsToAdd([]);
            }
        }
        else {
            setCompetitorDetail(row);
            setEventsToAdd([]);
        }
        toggleCompetitorDetail(true);
    };
    const handleEventSelected = (data) => {
        let clone = [...eventsToAdd];
        let thisIx = clone.findIndex(x => x.event_type_id === data.event_type_id);
        if (thisIx >= 0) {
            //it exists, so remove it
            clone.splice(thisIx, 1);
            setEventsToAdd([...clone]);
        }
        else {
            clone.push(data);
            setEventsToAdd([...clone]);
        }
    };
    const addEventsForCompetitor = () => {
        if (eventsToAdd && eventsToAdd.length > 0) {
            //Add a competitor item if one doesn't exist already
            //if it exists, just replace the quantity and events
            let clone = [...competitorItems];
            let itemIx = clone.findIndex(c => c.competitor_id === eventsToAdd[0].competitor_id);
            if (itemIx >= 0) {
                //exists, update events and quantity
                let item = clone[itemIx];
                item.quantity = eventsToAdd.length;
                item.events = eventsToAdd;
                item.events = eventsToAdd.map((ev, i) => {
                    let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                    let thisPrice = 40;
                    if (pricing) {
                        if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
                            thisPrice = pricing.additional_price_per_event;
                        }
                        else {
                            if (i === 0) {
                                thisPrice = pricing.entry_price;
                            }
                            else {
                                thisPrice = pricing.additional_price_per_event;
                            }
                        }
                    }
                    return {
                        ...ev,
                        price: thisPrice,
                    }
                });
                //we can assume that if there is already an event associated to the competitor, that the 1st event has already been charged.
                //Otherwise, charge the first event as first and the subsequent events udner the regular additional price
                if (competitorDetail.events && competitorDetail.events.length === 0) {

                }
                clone[itemIx] = item;
                setCompetitorItems([...clone]);
            }
            else {
                clone.push({
                    competitor_id: eventsToAdd[0].competitor_id,
                    competitor_detail: competitorDetail,
                    quantity: eventsToAdd.length,
                    events: eventsToAdd.map((ev, i) => {
                        let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                        let thisPrice = 40;
                        if (pricing) {
                            if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
                                thisPrice = pricing.additional_price_per_event;
                            }
                            else {
                                if (i === 0) {
                                    thisPrice = pricing.entry_price;
                                }
                                else {
                                    thisPrice = pricing.additional_price_per_event;
                                }
                            }
                        }
                        return {
                            ...ev,
                            price: thisPrice,
                        }
                    }),
                });
                setCompetitorItems([...clone]);
            }

        }
        toggleCompetitorDetail(false);
        toggleCompetitorLookup(false);
        setEventsToAdd([]);
    };
    const editCompetitorItem = (item) => {
        toggleCompetitorDetail(true);
        setCompetitorDetail(item.competitor_detail);
        setEventsToAdd(item.events);
    };
    const getCompetitorItemTotal = (item) => {
        let total = 0;
        if (item && item.events && item.events.length > 0) {
            item.events.forEach(e => total += e.price);
        }
        return total;
    };
    const getNewCompetitorItemTotal = (item) => {
        let total = 0;
        if (item && item.events && item.events.length > 0) {
            item.events.forEach(e => total += e.price);
        }
        return total;
    };
    const applyPromoCode = (code) => {
        setAppliedPromoCode(code);
    };
    const processTransaction = async (res) => {

        //now show the registration details
        toggleModalTransactionComplete(true);

        let regObj = {
            profile: profile,
            newCompetitorItems: newCompetitorItems,
            competitorItems: competitorItems,
            spectatorItems: spectatorItems,
            additionalItems: additionalItems,
            appliedPromoCode: appliedPromoCode,
            paymentMethod: res && (res.method ?? null),
            paymentIntent: res && (res.paymentIntent ?? null),
        };
        const presponse = await fetch(`/api/pos/${tournamentId}/processtransaction`, {
            method: 'POST',
            body: JSON.stringify(regObj, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(ex => {
            console.log('Caught Error')
        });

        const pdata = await presponse.json();

        const response = await fetch(`/api/pos/${tournamentId}/sendregistrationconfirmation?registrationId=` + encodeURIComponent(pdata.registrationId));
        const rdata = await response.ok;
        //if (rdata) {
        //    setResult('Success');
        //}
        //else {
        //    setResult('Fail');
        //}
        //setTimeout(() => { setResult(null); }, 5000);



        setTransactionRegistration(pdata);
        setCompletedRegistrationData({
            itemsCount: getItemsCount(),
            newCompetitorItems: newCompetitorItems,
            competitorItems: competitorItems,
            spectatorItems: spectatorItems,
            additionalItems: additionalItems,
            appliedPromoCode: appliedPromoCode,
            discountAmount: getDiscountAmount(appliedPromoCode),
            itemsTotal: getItemsTotal(),
            transactionRegistration: pdata,
            profile: profile
        });
        clearTransaction();

    };
    const applyProfile = (prof) => {
        setProfile(prof);
    };
    const clearTransaction = () => {
        setCont(Math.random());
        setProfile(null);
        setNewCompetitorItems([]);
        setCompetitorItems([]);
        setSpectatorItems([]);
        setAdditionalItems([]);
        setAppliedPromoCode(null);
        setTransactionRegistration(null);
        
    }
    //const handlePaymentChange = (e) => {
    //    console.log('From POS', e);
    //    if (e && e.complete === true) {
    //        console.log('set it to true');
    //        setPaymentReady(true);
    //    }
    //    else {
    //        console.log('set it to false');
    //        setPaymentReady(false);
    //    }
    //}
    const isPaymentDisabled = () => {
        let isDisabled = false;
        if (getItemsCount() === 0 || !liabilityAccept)
            return true;
        //if (!profile)
        //    return true;
        //if (profile && profile.email && !isEmail(profile.email))
        //    return true;
        return isDisabled;
    };
    const openCompetitorRegistration = (comp) => {
        if (newCompetitorRegistrationRef.current) {
            //find if there is an existing registration item for this student and use that instead if there is...
            let existingCompetitor = newCompetitorItems && newCompetitorItems.length > 0 ? newCompetitorItems.find(c => c.student.student_id === comp.studentId) : null;
            if (existingCompetitor) {
                newCompetitorRegistrationRef.current.open(existingCompetitor, 'events');
            }
            else {
                newCompetitorRegistrationRef.current.open({
                    student: {
                        student_id: comp.studentId,
                        first_name: comp.firstName,
                        last_name: comp.lastName,
                        association_id: comp.associationId,
                        date_of_birth: comp.dob,
                        height: comp.height,
                        weight: comp.weight,
                        school_id: comp.schoolId,
                        school: {
                            school_id: comp.schoolId,
                            school_name: comp.schoolName,
                            association_cd: associationCode,
                            address: {
                                city: comp.schoolCity,
                                state_prov: comp.schoolState,
                            }
                        },
                        rank_id: comp.rankId,
                        rank_name: comp.rankName,
                        rank_class_name: comp.rankClassName,
                        gender: comp.gender,
                        student_image_url: comp.imageUrl,
                        association_cd: associationCode,
                        competition_age: null
                    }
                }, 'events');
            }
        }

    };
    const editCompetitorRegistration = (comp) => {
        if (newCompetitorRegistrationRef.current) {
            newCompetitorRegistrationRef.current.open(comp, 'events');
        }

    };
    const newCompetitorAdded = (newComp) => {

        if (newComp && newComp.events?.length > 0) {
            let thisPrice = 40;
            newComp.events.filter((e) => !e.team_event_flag).forEach((ev, i) => {
                let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                if (pricing) {
                    if (i === 0) {
                        thisPrice = pricing.entry_price;
                    }
                    else {
                        if (i < pricing.entry_price_events) {
                            thisPrice = 0;
                        }
                        else {
                            thisPrice = pricing.additional_price_per_event;
                        }
                    }
                }
                ev.price= thisPrice;
            });
            newComp.events.filter((e) => e.team_event_flag).forEach((ev, i) => {
                let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                if (pricing) {
                    if (i === 0) {
                        thisPrice = pricing.entry_price;
                    }
                    else {
                        if (i < pricing.entry_price_events) {
                            thisPrice = 0;
                        }
                        else {
                            thisPrice = pricing.additional_price_per_event;
                        }
                    }
                }
                ev.price = thisPrice;
            });


        //    newComp.events = newComp.events.filter((e) => !e.team_event_flag).map((ev, i) => {
        //        let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
        //        if (pricing) {
        //            if (i === 0) {
        //                thisPrice = pricing.entry_price;
        //            }
        //            else {
        //                if (i < pricing.entry_price_events) {
        //                    thisPrice = 0;
        //                }
        //                else {
        //                    thisPrice = pricing.additional_price_per_event;
        //                }
        //            }
        //            //    if (i + 1 <= pricing.entry_price_events)
        //            //    if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
        //            //        thisPrice = pricing.additional_price_per_event;
        //            //    }
        //            //    else {
        //            //        if (i === 0) {
        //            //            thisPrice = pricing.entry_price;
        //            //        }
        //            //        else {
        //            //            thisPrice = pricing.additional_price_per_event;
        //            //        }
        //            //    }
        //        }
        //        return {
        //            ...ev,
        //            price: thisPrice,
        //        }
        //    });
        }

        let clone = newCompetitorItems;
        let thisItemIx = clone.findIndex(i => i.student.student_id === newComp.student.student_id);
        if (thisItemIx >= 0) {
            clone[thisItemIx] = newComp;
        }
        else {
            clone.push(newComp);
        }
        setNewCompetitorItems([...clone])


        //if (eventsToAdd && eventsToAdd.length > 0) {
        //    //Add a competitor item if one doesn't exist already
        //    //if it exists, just replace the quantity and events
        //    let clone = [...competitorItems];
        //    let itemIx = clone.findIndex(c => c.competitor_id === eventsToAdd[0].competitor_id);
        //    if (itemIx >= 0) {
        //        //exists, update events and quantity
        //        let item = clone[itemIx];
        //        item.quantity = eventsToAdd.length;
        //        item.events = eventsToAdd;
        //        item.events = eventsToAdd.map((ev, i) => {
        //            let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
        //            let thisPrice = 40;
        //            if (pricing) {
        //                if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
        //                    thisPrice = pricing.additional_price_per_event;
        //                }
        //                else {
        //                    if (i === 0) {
        //                        thisPrice = pricing.entry_price;
        //                    }
        //                    else {
        //                        thisPrice = pricing.additional_price_per_event;
        //                    }
        //                }
        //            }
        //            return {
        //                ...ev,
        //                price: thisPrice,
        //            }
        //        });
        //        //we can assume that if there is already an event associated to the competitor, that the 1st event has already been charged.
        //        //Otherwise, charge the first event as first and the subsequent events udner the regular additional price
        //        if (competitorDetail.events && competitorDetail.events.length === 0) {

        //        }
        //        clone[itemIx] = item;
        //        setCompetitorItems([...clone]);
        //    }
        //    else {
        //        clone.push({
        //            competitor_id: eventsToAdd[0].competitor_id,
        //            competitor_detail: competitorDetail,
        //            quantity: eventsToAdd.length,
        //            events: eventsToAdd.map((ev, i) => {
        //                let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
        //                let thisPrice = 40;
        //                if (pricing) {
        //                    if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
        //                        thisPrice = pricing.additional_price_per_event;
        //                    }
        //                    else {
        //                        if (i === 0) {
        //                            thisPrice = pricing.entry_price;
        //                        }
        //                        else {
        //                            thisPrice = pricing.additional_price_per_event;
        //                        }
        //                    }
        //                }
        //                return {
        //                    ...ev,
        //                    price: thisPrice,
        //                }
        //            }),
        //        });
        //        setCompetitorItems([...clone]);
        //    }

        //}
        //toggleCompetitorDetail(false);
        //toggleCompetitorLookup(false);
        //setEventsToAdd([]);



    };
    const addProfileStudent = async (e, studentId) => {
        await apiFetch.post('/api/profile/student', { profileId: profile.profileId, studentId: studentId, isPrimary: false })
            .then((resp) => {
                loadStudents();
            })
            .catch((ex) => {
            })
            .finally(() => {
            });
    };


    return (
        <>
            <div className="container-xl px-2 pb-4">

                {!modalTransactionComplete ?
                <div className="row g-3">
                    <div className="col-md-5 col-lg-4 order-md-last">

                        {/*<ProfileSearchTransaction key={"ProfileSearchTransaction" + cont} applyProfile={applyProfile} />*/}
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-secondary">Registration</span>
                            <span className="badge bg-secondary rounded-pill">{getItemsCount()}</span>
                        </h4>
                        <ul className="list-group mb-3">
                            {newCompetitorItems ?
                                newCompetitorItems.map((itm, i) =>
                                    <li key={i} className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeNewCompetitorItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat value={/*itm.events?.length*/1} displayType="text" className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">Competitor Registration <FontAwesomeIcon icon={faPenToSquare} className="text-secondary" style={{ cursor: "pointer" }} onClick={() => { editCompetitorRegistration(itm); }} /></h6>
                                            <small className="text-body-secondary">
                                                {itm.student.first_name} {itm.student.last_name}
                                                <br />
                                                {itm.events ? itm.events.map((e, ix) =>
                                                    <Fragment key={ix}>
                                                        {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                    </Fragment>
                                                )
                                                    :
                                                    null
                                                }
                                            </small>
                                        </div>
                                        <span className="text-body-secondary">
                                            <NumericFormat value={getNewCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </span>
                                    </li>
                                ) : null}
                            {competitorItems ?
                                competitorItems.map((itm, i) =>
                                    <li key={i} className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeCompetitorItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat value={itm.quantity} displayType="text" className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">Competitor Events <FontAwesomeIcon icon={faPenToSquare} className="text-secondary" style={{ cursor: "pointer" }} onClick={() => { editCompetitorItem(itm); } } /></h6>
                                            <small className="text-body-secondary">
                                                {itm.competitor_detail.first_name} {itm.competitor_detail.last_name}
                                                <br />
                                                {itm.events ? itm.events.map((e, ix) =>
                                                    <>
                                                        {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                    </>
                                                )
                                                    :
                                                    null
                                            }
                                            </small>
                                        </div>
                                        <span className="text-body-secondary">
                                            <NumericFormat value={getCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </span>
                                    </li>
                                ) : null}
                            {spectatorItems ?
                                spectatorItems.map((itm, i) =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeSpectatorItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat onFocus={handleQuantityFocus} value={itm.quantity} isAllowed={values => {
                                                const { formattedValue, floatValue } = values;
                                                if (floatValue == null) {
                                                    return formattedValue === '1'
                                                } else {
                                                    return (floatValue <= 99 && floatValue >= 1)
                                                }
                                            }} className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," onValueChange={(v, si) => handleSpectatorQuantityChange(i, v, si)} />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">Spectator</h6>
                                            <small className="text-body-secondary">{itm.pass_type} - <NumericFormat value={itm.price} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></small>
                                        </div>
                                        <span className="text-body-secondary"><NumericFormat value={itm.price * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                    </li>
                                ) : null}
                            {additionalItems ?
                                additionalItems.map((itm, i) =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeAdditionalItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat onFocus={handleQuantityFocus} value={itm.quantity} isAllowed={values => {
                                                const { formattedValue, floatValue } = values
                                                if (floatValue == null) {
                                                    return formattedValue === ''
                                                } else {
                                                    return (floatValue <= 99 && floatValue >= 1)
                                                }
                                            }} className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," onValueChange={(v, si) => handleAdditionalQuantityChange(i, v, si)} />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">{itm.price_description}</h6>
                                            <small className="text-body-secondary">{<NumericFormat value={itm.price_amount} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />}</small>
                                        </div>
                                        <span className="text-body-secondary"><NumericFormat value={itm.price_amount * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                    </li>
                                ) : null}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Product name</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$12</span>*/}
                            {/*</li>*/}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Second product</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$8</span>*/}
                            {/*</li>*/}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Third item</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$5</span>*/}
                            {/*</li>*/}
                            {appliedPromoCode && !appliedPromoCode.is_expired && !appliedPromoCode.is_exceeded && appliedPromoCode.discount_amount ?
                                <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                                    <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                        <button type="button" className="btn btn-default p-0" onClick={() => setAppliedPromoCode(null)}>
                                            <FontAwesomeIcon icon={faTrashCan} className="" />
                                        </button>
                                    </div>
                                    <div className="col ps-1 text-success">
                                        <h6 className="my-0">Promo Code: <b>{ appliedPromoCode.discount_cd}</b></h6>
                                        <small>{appliedPromoCode.discount_description}</small>
                                    </div>
                                    <span className="text-success"><NumericFormat value={getDiscountAmount(appliedPromoCode)} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></span>
                                </li>
                                :
                                null
                                }
                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total</span>
                                <strong><NumericFormat value={getItemsTotal()} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></strong>
                            </li>
                        </ul>

                        <PromoCode key={"PromoCode" + cont} appliedCode={applyPromoCode} />

                        {/*<form id="payment-form" onSubmit={processTransaction}>*/}
                        {/*    <hr className="my-4" />*/}
                        {/*    <div className="card p-2">*/}
                        {/*        {stripePromise ?*/}
                        {/*            <>*/}
                        {/*                <h6>Payment Card</h6>*/}
                        {/*                <Elements stripe={stripePromise} options={options}>*/}
                        {/*                    <StripePayment handlePaymentChange={handlePaymentChange} />*/}
                        {/*                </Elements>*/}
                        {/*            </>*/}
                        {/*            : <>Could not load payment component</>}*/}
                        {/*    </div>*/}

                        {/*    */}{/*options={{ clientSecret: 'sk_test_51O9dP4JjV5MFiluLt44OUhe08WkgPV2jrDOku40mW01MHfBtWdqDgbJfzgc2wpNKP0nEiMyNWD0VULgler4QMkiU00f9aGWgNK' }}*/}
                        {/*    */}{/*<Elements stripe={stripePromise} options={{ clientSecret, }} >*/}
                        {/*    */}{/*    <PaymentElement />*/}
                        {/*    */}{/*</Elements>*/}
                        {/*    <hr className="my-4" />*/}
                        {/*    {!(paymentReady && getItemsCount() !== 0) ? 'disabled' : 'not disabled'}*/}
                        {/*    <button className="w-100 btn btn-primary btn-lg" type="submit" disabled={!(paymentReady && getItemsCount() !== 0)} >Process Transaction</button>*/}
                        {/*</form>*/}
                            <hr className="my-4" />
                            <div className="row g-3">
                                <div className="col-sm-8">
                                    <Payment key={"Payment" + cont} disabled={isPaymentDisabled()} amount={getItemsTotal()} transactionCompleted={processTransaction} />
                                </div>
                                <div className="col-sm-4">
                                    <button type="button" className="w-100 btn btn-danger btn-lg" onClick={clearTransaction} disabled={false} >
                                        {false ?
                                            <FontAwesomeIcon icon={faSpinner} spin className="me-2 me-sm-2 me-md-0 me-lg-2" />
                                            :
                                            <FontAwesomeIcon icon={faTrashCan} className="me-2 me-sm-2 me-md-0 me-lg-2" />
                                        }
                                        <span className="d-inline-block d-sm-inline-block d-md-none d-lg-inline-block">Clear</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-12 pt-3">
                                    <small>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={liabilityAccept} onChange={() => setLiabilityAccept(!liabilityAccept)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Accept liability release and refund policy - No Refunds
                                            </label>
                                        </div>
                                        <Link className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" to="https://open.sidekick.events/assets/Release%20of%20Liability%20-%20Champion%20Karate%202024.pdf" target="_new">(View liability release form)</Link>
                                    </small>
                                </div>
                            </div>


                    </div>
                    <div className="col-md-7 col-lg-8">
                        <nav className="nav nav-borders pb-3" >
                            <button onClick={(e) => { e.preventDefault(); setProductGroupFilter(null); }} className={`nav-link ${productGroupFilter == null ? 'active' : ''}`}><FontAwesomeIcon icon={faUser} className="pe-2" />Competitors</button>
                            <button onClick={(e) => { e.preventDefault(); setProductGroupFilter('SPECTATORS'); }} className={`nav-link ${productGroupFilter === 'SPECTATORS' ? 'active' : ''}`}><FontAwesomeIcon icon={faUsersLine} className="pe-2" />Spectators</button>
                            {getAddtionalProductsGroups().map((grp, i) =>
                                <button key={i} onClick={(e) => { e.preventDefault(); setProductGroupFilter(grp.price_name); }} className={`nav-link text-capitalize ${productGroupFilter && productGroupFilter === grp.price_name ? 'active' : ''}`}><FontAwesomeIcon icon={getCardIcon(grp.price_name)} className="pe-2" />{grp.price_name.toLowerCase()}</button>
                            )}
                        </nav>
                        <div className="row dashboard justify-content-center">
                            <NewCompetitorRegistration ref={newCompetitorRegistrationRef} onComplete={newCompetitorAdded} editCompetitorData={editNewCompetitor} allowEventSelection />
                            {!productGroupFilter ?
                                <>
                                    {students ?
                                            students.map((s, i) =>
                                                    <DashboardCard key={i}
                                                        iconImg={<div className="mb-4">
                                                            <StudentCardImageHex imageUrl={s.imageUrl} alt="My Image" size="sm" resp />
                                                        </div>}
                                                        title={<>Register <br />{s.firstName} {s.lastName}<br /></>}
                                                        onClick={() => openCompetitorRegistration(s)}
                                                    />
                                            )
                                     : null}
                                    {/*<NewCompetitorDashboardCard ref={newCompetitorDashboardCardRef} onComplete={newCompetitorAdded} editCompetitorData={editNewCompetitor} allowEventSelection />*/}
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 my-md-3 py-md-2 my-sm-1 py-sm-1 py-1 my-1">
                                        <CreateStudentCard onComplete={(student) => addProfileStudent(null, student.student_id)} />
                                    </div>
                                    {/*<DashboardCard icon={faUser} title="Add Event To Competitor" onClick={() => openCompetitorLookup()} />*/}
                                </>
                                : null}
                                {productGroupFilter && productGroupFilter === 'SPECTATORS' ? <>
                                    <DashboardCard icon={faUsersLine} title={<>Regular<br /><NumericFormat value={spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount ? spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount : 15} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addSpectatorItem({ pass_type: 'Regular', price: spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount ? spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount : 15 })} />
                                    {spectatorPricing.find(x => x.pass_type === "VIP") ?
                                        <DashboardCard icon={faUsersLine} title={<>
                                            <FontAwesomeIcon icon={faStar} className="ps-2 text-warning" />
                                            <FontAwesomeIcon icon={faStar} className="pe-2 text-warning" />
                                            VIP
                                            <FontAwesomeIcon icon={faStar} className="ps-2 text-warning" />
                                            <FontAwesomeIcon icon={faStar} className="pe-2 text-warning" />
                                            <br /><NumericFormat value={spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "VIP").price_amount ? spectatorPricing.find(x => x.pass_type === "VIP").price_amount : 25} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addSpectatorItem({ pass_type: 'VIP', price: spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "VIP").price_amount ? spectatorPricing.find(x => x.pass_type === "VIP").price_amount : 25 })} />
                                        : null}
                                </>
                                    : null}
                                {getAdditionalProducts().map((prd, i) =>
                                    <DashboardCard key={i} icon={getCardIcon(prd.price_name)} iconAlt={prd.price_name} title={<>{prd.price_description}<br /><NumericFormat value={prd.price_amount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addAdditionalItem(prd)} />
                            )}
                        </div>
                    </div>
                </div>
                : null }
                <div className="row g-3 pt-5">
                    {/*{searchResults ? searchResults.map((comp, i) => */}
                    {/*    <div>*/}
                    {/*        {comp.first_name} */}
                    {/*        {comp.last_name} */}
                    {/*        {comp.school_name}*/}
                    {/*        {comp.profile_image_url ?*/}
                    {/*            <img src={comp.profile_image_url} height="50" />*/}
                    {/*            : null}*/}
                    {/*    </div>*/}
                    {/*)*/}
                    {/*    : null}*/}
                    
                    <Modal isOpen={modalCompetitorLookup} toggle={() => toggleCompetitorLookup()} size="xl" fade={true}>
                        <ModalHeader toggle={() => toggleCompetitorLookup()}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />Competitor Lookup
                        </ModalHeader>
                        <ModalBody className="">
                            {/*<SearchCompetitorWithResults itemClicked={searchItemClicked} />*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => toggleCompetitorLookup()}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalCompetitorDetail} toggle={() => toggleCompetitorDetail(false)} size="xl" fade={true}>
                        <ModalHeader toggle={() => toggleCompetitorDetail(false)}>
                            <FontAwesomeIcon icon={faUser} className="pe-2" />Competitor Detail
                        </ModalHeader>
                        <ModalBody className="">
                            {/*<CompetitorDetail competitorDetail={competitorDetail} editEvents={true} handleEventSelect={handleEventSelected} eventsSelected={eventsToAdd} />*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => toggleCompetitorDetail(false)}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={addEventsForCompetitor} disabled={!(eventsToAdd && eventsToAdd.length > 0)}>
                                Add Events
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalTransactionComplete} toggle={() => toggleModalTransactionComplete(false)} size="xl" fade={true} backdrop="static"> {/*backdrop="static"*/}
                        <ModalHeader>
                            <FontAwesomeIcon icon={faThumbsUp} className="pe-2" />Transaction{completedRegistrationData ? " Complete" : "..."}
                        </ModalHeader>
                        <ModalBody className="">
                            {completedRegistrationData ?
                                <div className="row g-3">
                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="checkout-scard card border-1 rounded">
                                                <div className="card-body text-center my-4">
                                                    <p className="card-icon"><i className="icon an an-shield-check fs-1"></i></p>
                                                    <h4 className="card-title">Thank you for registering!</h4>
                                                    <p className="card-text mb-1">You will receive a confirmation email with details of your registration.</p>
                                                    <p className="card-text mb-1">
                                                        <h4>
                                                            <span className="badge bg-success">Registration #{completedRegistrationData.transactionRegistration.registrationId}</span>
                                                        </h4>
                                                    </p>
                                                    <p className="card-text mt-3">
                                                        <Button className="col-auto px-5" color="primary" onClick={() => {
                                                            clearTransaction();
                                                            toggleModalTransactionComplete();
                                                            navigate('/', { replace: true });
                                                        }}>
                                                            Continue
                                                        </Button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<h1 className="display-6 pt-3">Transaction Complete</h1>*/}
                                        {/*{completedRegistrationData.transactionRegistration ?*/}
                                        {/*    <>*/}
                                        {/*        <h4 className="d-flex justify-content-between align-items-center mb-3">*/}
                                        {/*            <span className="text-secondary">Registration</span>*/}
                                        {/*            <span className="badge bg-secondary rounded-pill">#{completedRegistrationData.transactionRegistration.registrationId}</span>*/}
                                        {/*        </h4>*/}
                                        {/*        <div className="card p-2 mb-3">*/}
                                        {/*            <div className="row d-flex justify-content-between align-items-center g-3 px-2">*/}
                                        {/*                <div className="col text-center">*/}
                                        {/*                    <SendRegistrationConfirmation disabled={!(completedRegistrationData.profile && isEmail(completedRegistrationData.profile.email))} registrationId={completedRegistrationData.transactionRegistration.registrationId} />*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </>*/}
                                        {/*    : null}*/}
                                        {/*<h1 className="display-6 pt-3">Transaction Complete</h1>*/}
                                    {/*    {completedRegistrationData.profile ?*/}
                                    {/*        <>*/}
                                    {/*            <h4 className="d-flex justify-content-between align-items-center mb-3">*/}
                                    {/*                <span className="text-secondary">Profile</span>*/}
                                    {/*                <span className="badge bg-secondary rounded-pill"></span>*/}
                                    {/*            </h4>*/}
                                    {/*            <div className="card p-2 mb-3">*/}
                                    {/*                <div className="row d-flex g-3 px-2">*/}
                                    {/*                    <div className="col-12">*/}
                                    {/*                        <h6>Profile #{completedRegistrationData.profile.profileId}</h6>*/}
                                    {/*                        <h6>{completedRegistrationData.profile.firstName} {completedRegistrationData.profile.lastName}</h6>*/}
                                    {/*                        <h6>{completedRegistrationData.profile.email}</h6>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </>*/}
                                    {/*        : null}*/}
                                    </div>
                                    <div className="col">
                                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                                            <span className="text-secondary">Summary</span>
                                            <span className="badge bg-secondary rounded-pill">{completedRegistrationData.itemsCount}</span>
                                        </h4>
                                        <ul className="list-group mb-3">
                                            {completedRegistrationData.newCompetitorItems ?
                                                completedRegistrationData.newCompetitorItems.map((itm, i) =>
                                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                                        <div className="col ps-1">
                                                            <h6 className="my-0">{itm.student.first_name} {itm.student.last_name}</h6>
                                                            <small className="text-body-secondary">
                                                                {itm.events ? itm.events.map((e, ix) =>
                                                                    <>
                                                                        {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                                    </>
                                                                )
                                                                    :
                                                                    null
                                                                }
                                                            </small>
                                                        </div>
                                                        <span className="text-body-secondary">
                                                            <NumericFormat value={getNewCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                                        </span>
                                                    </li>
                                                ) : null}
                                            {completedRegistrationData.competitorItems ?
                                                completedRegistrationData.competitorItems.map((itm, i) =>
                                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                                        <div className="col ps-1">
                                                            <h6 className="my-0">{itm.competitor_detail.first_name} {itm.competitor_detail.last_name}</h6>
                                                            <small className="text-body-secondary">
                                                                {itm.events ? itm.events.map((e, ix) =>
                                                                    <>
                                                                        {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                                    </>
                                                                )
                                                                    :
                                                                    null
                                                                }
                                                            </small>
                                                        </div>
                                                        <span className="text-body-secondary">
                                                            <NumericFormat value={getCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                                        </span>
                                                    </li>
                                                ) : null}
                                            {completedRegistrationData.spectatorItems ?
                                                completedRegistrationData.spectatorItems.map((itm, i) =>
                                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                                        <div className="col-1 justify-content-center text-end">
                                                            {itm.quantity}
                                                        </div>
                                                        <div className="col-1 text-secondary text-center">
                                                            <small className="">X</small>
                                                        </div>
                                                        <div className="col ps-1">
                                                            <h6 className="my-0">Spectator - {itm.pass_type} <small className="text-body-secondary">(<NumericFormat value={itm.price} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />)</small></h6>
                                                        </div>
                                                        <span className="text-body-secondary"><NumericFormat value={itm.price * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                                    </li>
                                                ) : null}
                                            {completedRegistrationData.additionalItems ?
                                                completedRegistrationData.additionalItems.map((itm, i) =>
                                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                                        <div className="col-1 justify-content-center text-end">
                                                            {itm.quantity}
                                                        </div>
                                                        <div className="col-1 text-secondary text-center">
                                                            <small className="">X</small>
                                                        </div>
                                                        <div className="col ps-1">
                                                            <h6 className="my-0">{itm.price_description} <small className="text-body-secondary">(<NumericFormat value={itm.price_amount} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />)</small></h6>
                                                        </div>
                                                        <span className="text-body-secondary"><NumericFormat value={itm.price_amount * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                                    </li>
                                                ) : null}
                                            {completedRegistrationData.appliedPromoCode && !completedRegistrationData.appliedPromoCode.is_expired && !completedRegistrationData.appliedPromoCode.is_exceeded && completedRegistrationData.appliedPromoCode.discount_amount ?
                                                <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                                                    <div className="col ps-1 text-success">
                                                        <h6 className="my-0">Promo Code: <b>{completedRegistrationData.appliedPromoCode.discount_cd}</b></h6>
                                                        <small>{completedRegistrationData.appliedPromoCode.discount_description}</small>
                                                    </div>
                                                    <span className="text-success"><NumericFormat value={completedRegistrationData.discountAmount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></span>
                                                </li>
                                                :
                                                null
                                            }
                                            <li className="list-group-item d-flex justify-content-between">
                                                <span>Total</span>
                                                <strong><NumericFormat value={completedRegistrationData.itemsTotal} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : 
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="col-12">
                                            <div className="checkout-scard card border-1 rounded">
                                                <div className="card-body text-center my-4">
                                                    <p className="display-1">
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    </p>
                                                    <h4 className="card-title">Processing...</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </ModalBody>
                        {/*<ModalFooter className="d-flex justify-content-center">*/}
                        {/*    <Button className="col-12 col-sm-12 col-xl-3 col-lg-6 col-md-9" color="primary" onClick={() => {*/}
                        {/*        clearTransaction();*/}
                        {/*        toggleModalTransactionComplete();*/}
                        {/*    }}>*/}
                        {/*        Continue*/}
                        {/*    </Button>*/}
                        {/*</ModalFooter>*/}
                    </Modal>

                </div>

            </div>
        </>
    );

}
export default POS;
