import { BrowserRouter, useLocation, Route, Routes, RouterProvider, createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";

import RequireAuth from '@auth-kit/react-router/RequireAuth'

import Home from "./components/Home";
import { Login } from "./components/Login";
import POS from "./components/pos/index";
import Testing from "./components/testing/index";
import Complete from "./components/testing/Complete";
import Register from "./components/profile/Register";
import Profile from "./components/profile/index";
import ResetPassword from "./components/profile/ResetPassword";
import Tournament from "./components/tournament/index";


//const Routes = () => {
//    const { token } = useAuth();

//    const publicRoutes = [
//        {
//            path: "/",
//            element: <Home />,
//        },
//    ];
//    const authenticatedRoutes = [
//        {
//            path: "/",
//            element: <ProtectedRoute />,
//            children: [
//                ...AppRoutes,
//                {
//                    path: "",
//                    element: <div>User Home Page</div>,
//                },
//                {
//                    path: "/register",
//                    element: <div>register</div>,
//                },
//                {
//                    path: "/logout",
//                    element: <div>Logout</div>,
//                },
//            ]
//        },
//    ];
//    const notAuthenticatedRoutes = [
//        {
//            path: "/",
//            element: <div>Home Page</div>,
//        },
//        {
//            path: "/login",
//            element: <Login />,
//        },
//    ];

//    const router = createBrowserRouter([
//        ...publicRoutes,
//        ...(!token ? notAuthenticatedRoutes : []),
//        ...authenticatedRoutes,
//    ]);

//    return <RouterProvider router={router} basename="/" />;
//};

/*export default Routes;*/
import { Layout } from './components/Layout';

const RoutesComponent = () => {
    return (
        /*<Layout>*/
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path={'/'} element={<Home />} />
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/profile/register'} element={<Register />} />
                    <Route path={'/profile/resetpassword'} element={<ResetPassword />} />
                    <Route path={'/pos'} element={<POS />} />
                    <Route path={'/profile'} element={
                        <RequireAuth fallbackPath={'/login'}>
                            <Profile />
                        </RequireAuth>
                    } />
                    <Route path={'/profile/student/:studentId'} element={
                        <RequireAuth fallbackPath={'/login'}>
                            <Profile />
                        </RequireAuth>
                    } />
                    <Route path={'/tournament'} element={
                        <RequireAuth fallbackPath={'/login?r=/tournament'}>
                            <Tournament />
                        </RequireAuth>
                    } />
                </Routes>
            </Layout>
        </BrowserRouter>
        /*</Layout>*/
    )
};
export default RoutesComponent;

const ProtectedRoute = () => {
    const { token } = useAuth();
    const location = useLocation();

    // Check if the user is authenticated
    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // If authenticated, render the child routes
    return <Outlet />;
};

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/pos',
        element: <POS />
    },
    {
        path: '/testing',
        element: <Testing />
    },
    {
        path: '/testing/complete',
        element: <Complete />
    },
];

/*export default AppRoutes;*/
