import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { NavLink, Link } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faPenToSquare, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faUser, faStar, faShirt, faPallet, faMagnifyingGlass, faSpinner, faRoadBarrier, faTrashCan, faUsersLine, faReceipt } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import DashboardCard from "./DashboardCard";
import { NumericFormat } from 'react-number-format';
//import SearchCompetitorWithResults from '../competitors/SearchCompetitorWithResults';
//import CompetitorDetail from '../competitors/CompetitorDetail';
import PromoCode from './PromoCode';
import ProfileSearchTransaction from './ProfileSearchTransaction';
import Payment from '../tournament/Payment';
import SendRegistrationConfirmation from './SendRegistrationConfirmation';
import { useApiFetch } from '../../ApiFetch';

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function POS() {
    const apiFetch = useApiFetch();
    const navigate = useNavigate();
    const [additionalProducts, setAdditionalProducts] = useState([]);

    const [modalCompetitorLookup, setModalCompetitorLookup] = useState(false);
    const [competitorDetail, setCompetitorDetail] = useState(null);
    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);
    const [eventsToAdd, setEventsToAdd] = useState([]);

    const [registrationPricing, setRegistrationPricing] = useState([]);
    const [spectatorPricing, setSpectatorPricing] = useState([]);

    const [profile, setProfile] = useState(null);
    const [competitorItems, setCompetitorItems] = useState([]);
    const [spectatorItems, setSpectatorItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);
    const [appliedPromoCode, setAppliedPromoCode] = useState(null);
    const [productGroupFilter, setProductGroupFilter] = useState(null);

    const [modalTransactionComplete, setModalTransactionComplete] = useState(false);
    const [transactionRegistration, setTransactionRegistration] = useState(null);

    const [cont, setCont] = useState(null);


    const toggleCompetitorLookup = (open) => setModalCompetitorLookup(open === undefined ? !modalCompetitorLookup : open);
    const toggleCompetitorDetail = (open) => {
        setModalCompetitorDetail(open === undefined ? !modalCompetitorDetail : open);
    //    setEventsToAdd([]);
    };
    const toggleModalTransactionComplete = (open) => setModalTransactionComplete(open === undefined ? !modalTransactionComplete : open);
    

    useEffect(() => {
        getData();
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [cont]);

    const getData = async () => {
        const response = await apiFetch.get('/api/pos/4001/additionalproducts');
        const rdata = await response.data;
        //const response = await fetch('/api/pos/4001/additionalproducts');
        //const rdata = await response.json();
        setAdditionalProducts(rdata);

        const presponse = await fetch('/api/pos/4001/registrationpricing?date=' + encodeURIComponent(moment().format('YYYY-MM-DD')))
        const pdata = await presponse.json();
        setRegistrationPricing(pdata);

        const sresponse = await fetch('/api/pos/4001/spectatorpricing?date=' + encodeURIComponent(moment().format('YYYY-MM-DD')))
        const sdata = await sresponse.json();
        setSpectatorPricing(sdata);
    }
    const openCompetitorLookup = row => {
        toggleCompetitorLookup(true);
        setCompetitorDetail(row);
    }
    const getCardIcon = (priceName) => {
        switch (priceName.toLowerCase()) {
            case "concrete":
                return faPallet;
                break;
            case "boards":
                return faRoadBarrier;
                break;
            case "tshirt":
                return faShirt;
                break;
            default:
                return null;
                break;
        }
    }



    const addSpectatorItem = (item) => {
        let clone = [...spectatorItems];

        let thisItemIndex = clone.findIndex(i => i.pass_type === item.pass_type);
        if (thisItemIndex >= 0) {
            let thisItem = clone[thisItemIndex];
            thisItem.quantity += 1
            clone[thisItemIndex] = thisItem;
            setSpectatorItems([...clone]);
        }
        else {
            let thisItem = { ...item, quantity: 1 };
            setSpectatorItems((prevItem) => [
                ...prevItem,
                thisItem,
            ]);
        }
    }
    const addAdditionalItem = (prd) => {

        let clone = [...additionalItems];

        let thisItemIndex = clone.findIndex(i => i.additional_price_id === prd.additional_price_id);
        if (thisItemIndex >= 0) {
            let thisItem = clone[thisItemIndex];
            thisItem.quantity += 1
            clone[thisItemIndex] = thisItem;
            setAdditionalItems([...clone]);
        }
        else {
            let thisItem = { ...prd, quantity: 1 };
            setAdditionalItems((prevItem) => [
                ...prevItem,
                thisItem,
            ]);
        }

    };
    const handleSpectatorQuantityChange = (ix, values, sourceInfo) => {
        if (sourceInfo.source !== 'event')
            return;

        let clone = [...spectatorItems];
        let item = clone[ix];
        item.quantity = values.floatValue;
        clone[ix] = item;
        setSpectatorItems([...clone]);

    };
    const handleAdditionalQuantityChange = (ix, values, sourceInfo) => {
        if (sourceInfo.source !== 'event')
            return;

        let clone = [...additionalItems];
        let item = clone[ix];
        item.quantity = values.floatValue;
        clone[ix] = item;
        setAdditionalItems([...clone]);

    };
    const handleQuantityFocus = (e) => {
        e.target.select();
    };

    const getAddtionalProductsGroups = () => {
        let groups = [];
        if (additionalProducts) {
            groups = Array.from(new Set(additionalProducts.map(e => e.price_name)))
                .map(id => {
                    return {
                        price_name: id
                    }
                });
        }
        return groups;
    };
    const getAdditionalProducts = () => {
        let prods = []
        if (productGroupFilter && additionalProducts) {
            return additionalProducts.filter(p => p.price_name === productGroupFilter);
        }
        return prods;
    };
    const getItemsTotal = () => {
        let total = 0;
        total = getItemsSubTotal();
        if (appliedPromoCode && !appliedPromoCode.is_expired && !appliedPromoCode.is_exceeded && appliedPromoCode.discount_amount) {
            if (appliedPromoCode.discount_type === 'AMT') {
                total += -appliedPromoCode.discount_amount;
            }
            else if (appliedPromoCode.discount_type === 'PCT') {
                total = (1 - appliedPromoCode.discount_amount) * total;
            }
        }
        return (total < 0) ? 0 : total;
    };
    const getItemsSubTotal = () => {
        let total = 0;
        if (competitorItems) {
            competitorItems.forEach(c => { total += getCompetitorItemTotal(c) });
        }
        if (spectatorItems) {
            spectatorItems.forEach(i => { total += i.price * i.quantity });
        }
        if (additionalItems) {
            additionalItems.forEach(i => { total += i.price_amount * i.quantity });
        }
        return total;
    };
    const getDiscountAmount = (promo) => {
        if (promo && !promo.is_expired && !promo.is_exceeded && promo.discount_amount) {
            if (promo.discount_type === 'AMT') {
                return -appliedPromoCode.discount_amount;
            }
            else if (promo.discount_type === 'PCT') {
                return -promo.discount_amount * getItemsSubTotal();
            }
        }
    }

    const getItemsCount = () => {
        let count = 0;
        if (competitorItems) {
            competitorItems.forEach(c => { count += c.events ? c.events.length : 0 });
        }
        if (spectatorItems) {
            spectatorItems.forEach(i => { count += i.quantity });
        }
        if (additionalItems) {
            additionalItems.forEach(i => { count += i.quantity });
        }
        return count;
    };
    const removeCompetitorItem = (index) => {
        let clone = [...competitorItems];
        clone.splice(index, 1);
        setCompetitorItems([...clone]);
    }
    const removeSpectatorItem = (index) => {
        let clone = [...spectatorItems];
        clone.splice(index, 1);
        setSpectatorItems([...clone]);
    }
    const removeAdditionalItem = (index) => {
        let clone = [...additionalItems];
        clone.splice(index, 1);
        setAdditionalItems([...clone]);
    }
    const searchItemClicked = (row) => {
        if (competitorItems) {
            
            let existingCompetitor = competitorItems.find(comp => comp.competitor_id === row.competitor_id);
            if (existingCompetitor) {
                editCompetitorItem(existingCompetitor);
            }
            else {
                setCompetitorDetail(row);
                setEventsToAdd([]);
            }
        }
        else {
            setCompetitorDetail(row);
            setEventsToAdd([]);
        }
        toggleCompetitorDetail(true);
    };
    const handleEventSelected = (data) => {
        let clone = [...eventsToAdd];
        let thisIx = clone.findIndex(x => x.event_type_id === data.event_type_id);
        if (thisIx >= 0) {
            //it exists, so remove it
            clone.splice(thisIx, 1);
            setEventsToAdd([...clone]);
        }
        else {
            clone.push(data);
            setEventsToAdd([...clone]);
        }
    };
    const addEventsForCompetitor = () => {
        if (eventsToAdd && eventsToAdd.length > 0) {
            //Add a competitor item if one doesn't exist already
            //if it exists, just replace the quantity and events
            let clone = [...competitorItems];
            let itemIx = clone.findIndex(c => c.competitor_id === eventsToAdd[0].competitor_id);
            if (itemIx >= 0) {
                //exists, update events and quantity
                let item = clone[itemIx];
                item.quantity = eventsToAdd.length;
                item.events = eventsToAdd;
                item.events = eventsToAdd.map((ev, i) => {
                    let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                    let thisPrice = 40;
                    if (pricing) {
                        if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
                            thisPrice = pricing.additional_price_per_event;
                        }
                        else {
                            if (i === 0) {
                                thisPrice = pricing.entry_price;
                            }
                            else {
                                thisPrice = pricing.additional_price_per_event;
                            }
                        }
                    }
                    return {
                        ...ev,
                        price: thisPrice,
                    }
                });
                //we can assume that if there is already an event associated to the competitor, that the 1st event has already been charged.
                //Otherwise, charge the first event as first and the subsequent events udner the regular additional price
                if (competitorDetail.events && competitorDetail.events.length === 0) {

                }
                clone[itemIx] = item;
                setCompetitorItems([...clone]);
            }
            else {
                clone.push({
                    competitor_id: eventsToAdd[0].competitor_id,
                    competitor_detail: competitorDetail,
                    quantity: eventsToAdd.length,
                    events: eventsToAdd.map((ev, i) => {
                        let pricing = registrationPricing ? registrationPricing.find(rp => rp.team_price_flag === ev.team_event_flag) : null;
                        let thisPrice = 40;
                        if (pricing) {
                            if (competitorDetail && competitorDetail.events && competitorDetail.events.length !== 0) {
                                thisPrice = pricing.additional_price_per_event;
                            }
                            else {
                                if (i === 0) {
                                    thisPrice = pricing.entry_price;
                                }
                                else {
                                    thisPrice = pricing.additional_price_per_event;
                                }
                            }
                        }
                        return {
                            ...ev,
                            price: thisPrice,
                        }
                    }),
                });
                setCompetitorItems([...clone]);
            }

        }
        toggleCompetitorDetail(false);
        toggleCompetitorLookup(false);
        setEventsToAdd([]);
    }
    const editCompetitorItem = (item) => {
        toggleCompetitorDetail(true);
        setCompetitorDetail(item.competitor_detail);
        setEventsToAdd(item.events);
    };
    const getCompetitorItemTotal = (item) => {
        let total = 0;
        if (item && item.events && item.events.length > 0) {
            item.events.forEach(e => total += e.price);
        }
        return total;
    };
    const applyPromoCode = (code) => {
        setAppliedPromoCode(code);
    };
    const processTransaction = async (res) => {

        let regObj = {
            profile: profile,
            competitorItems: competitorItems,
            spectatorItems: spectatorItems,
            additionalItems: additionalItems,
            appliedPromoCode: appliedPromoCode,
            paymentMethod: res && (res.method ?? null),
            paymentIntent: res && (res.paymentIntent ?? null),
        };
        const presponse = await fetch('/api/pos/4001/processtransaction', {
            method: 'POST',
            body: JSON.stringify(regObj, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(ex => {
            console.log('Caught Error')
        });

        const pdata = await presponse.json();
        setTransactionRegistration(pdata);
        //now show the registration details
        toggleModalTransactionComplete(true);

    };
    const applyProfile = (prof) => {
        setProfile(prof);
    };
    const clearTransaction = () => {
        setCont(Math.random());
        setProfile(null);
        setCompetitorItems([]);
        setSpectatorItems([]);
        setAdditionalItems([]);
        setAppliedPromoCode(null);
        setTransactionRegistration(null);
        
    }
    //const handlePaymentChange = (e) => {
    //    console.log('From POS', e);
    //    if (e && e.complete === true) {
    //        console.log('set it to true');
    //        setPaymentReady(true);
    //    }
    //    else {
    //        console.log('set it to false');
    //        setPaymentReady(false);
    //    }
    //}
    const isPaymentDisabled = () => {
        let isDisabled = false;
        if (getItemsCount() === 0)
            return true;
        //if (!profile)
        //    return true;
        //if (profile && profile.email && !isEmail(profile.email))
        //    return true;
        return isDisabled;
    };

    return (
        <>
            <div className="container-xl px-2 pb-4">

                <div className="row g-3">
                    <div className="col-md-5 col-lg-4 order-md-last">

                        <ProfileSearchTransaction key={"ProfileSearchTransaction" + cont} applyProfile={applyProfile} />
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-secondary">Transaction</span>
                            <span className="badge bg-secondary rounded-pill">{getItemsCount()}</span>
                        </h4>
                        <ul className="list-group mb-3">
                            {competitorItems ?
                                competitorItems.map((itm, i) =>
                                    <li key={i} className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeCompetitorItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat value={itm.quantity} displayType="text" className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">Competitor Events <FontAwesomeIcon icon={faPenToSquare} className="text-secondary" style={{ cursor: "pointer" }} onClick={() => { editCompetitorItem(itm); } } /></h6>
                                            <small className="text-body-secondary">
                                                {itm.competitor_detail.first_name} {itm.competitor_detail.last_name}
                                                <br />
                                                {itm.events ? itm.events.map((e, ix) =>
                                                    <>
                                                        {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                    </>
                                                )
                                                    :
                                                    null
                                            }
                                            </small>
                                        </div>
                                        <span className="text-body-secondary">
                                            <NumericFormat value={getCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                        </span>
                                    </li>
                                ) : null}
                            {spectatorItems ?
                                spectatorItems.map((itm, i) =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeSpectatorItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat onFocus={handleQuantityFocus} value={itm.quantity} isAllowed={values => {
                                                const { formattedValue, floatValue } = values;
                                                if (floatValue == null) {
                                                    return formattedValue === '1'
                                                } else {
                                                    return (floatValue <= 99 && floatValue >= 1)
                                                }
                                            }} className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," onValueChange={(v, si) => handleSpectatorQuantityChange(i, v, si)} />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">Spectator</h6>
                                            <small className="text-body-secondary">{itm.pass_type} - <NumericFormat value={itm.price} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></small>
                                        </div>
                                        <span className="text-body-secondary"><NumericFormat value={itm.price * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                    </li>
                                ) : null}
                            {additionalItems ?
                                additionalItems.map((itm, i) =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                            <button type="button" className="btn btn-default p-0" onClick={() => removeAdditionalItem(i)}>
                                                <FontAwesomeIcon icon={faTrashCan} className="" />
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex flex-column justify-content-center">
                                            <NumericFormat onFocus={handleQuantityFocus} value={itm.quantity} isAllowed={values => {
                                                const { formattedValue, floatValue } = values
                                                if (floatValue == null) {
                                                    return formattedValue === ''
                                                } else {
                                                    return (floatValue <= 99 && floatValue >= 1)
                                                }
                                            }} className="form-control form-control-sm text-end" style={{ width: "35px" }} decimalScale={0} fixedDecimalScale thousandSeparator="," onValueChange={(v, si) => handleAdditionalQuantityChange(i, v, si)} />
                                        </div>
                                        <div className="col-auto px-2 d-flex flex-column justify-content-center text-secondary">
                                            <small>X</small>
                                        </div>
                                        <div className="col ps-1">
                                            <h6 className="my-0">{itm.price_description}</h6>
                                            <small className="text-body-secondary">{<NumericFormat value={itm.price_amount} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />}</small>
                                        </div>
                                        <span className="text-body-secondary"><NumericFormat value={itm.price_amount * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                    </li>
                                ) : null}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Product name</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$12</span>*/}
                            {/*</li>*/}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Second product</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$8</span>*/}
                            {/*</li>*/}
                            {/*<li className="list-group-item d-flex justify-content-between lh-sm">*/}
                            {/*    <div>*/}
                            {/*        <h6 className="my-0">Third item</h6>*/}
                            {/*        <small className="text-body-secondary">Brief description</small>*/}
                            {/*    </div>*/}
                            {/*    <span className="text-body-secondary">$5</span>*/}
                            {/*</li>*/}
                            {appliedPromoCode && !appliedPromoCode.is_expired && !appliedPromoCode.is_exceeded && appliedPromoCode.discount_amount ?
                                <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                                    <div className="col-auto pe-2 d-flex flex-column justify-content-center text-secondary">
                                        <button type="button" className="btn btn-default p-0" onClick={() => setAppliedPromoCode(null)}>
                                            <FontAwesomeIcon icon={faTrashCan} className="" />
                                        </button>
                                    </div>
                                    <div className="col ps-1 text-success">
                                        <h6 className="my-0">Promo Code: <b>{ appliedPromoCode.discount_cd}</b></h6>
                                        <small>{appliedPromoCode.discount_description}</small>
                                    </div>
                                    <span className="text-success"><NumericFormat value={getDiscountAmount(appliedPromoCode)} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></span>
                                </li>
                                :
                                null
                                }
                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total</span>
                                <strong><NumericFormat value={getItemsTotal()} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></strong>
                            </li>
                        </ul>

                        <PromoCode key={"PromoCode" + cont} appliedCode={applyPromoCode} />

                        {/*<form id="payment-form" onSubmit={processTransaction}>*/}
                        {/*    <hr className="my-4" />*/}
                        {/*    <div className="card p-2">*/}
                        {/*        {stripePromise ?*/}
                        {/*            <>*/}
                        {/*                <h6>Payment Card</h6>*/}
                        {/*                <Elements stripe={stripePromise} options={options}>*/}
                        {/*                    <StripePayment handlePaymentChange={handlePaymentChange} />*/}
                        {/*                </Elements>*/}
                        {/*            </>*/}
                        {/*            : <>Could not load payment component</>}*/}
                        {/*    </div>*/}

                        {/*    */}{/*options={{ clientSecret: 'sk_test_51O9dP4JjV5MFiluLt44OUhe08WkgPV2jrDOku40mW01MHfBtWdqDgbJfzgc2wpNKP0nEiMyNWD0VULgler4QMkiU00f9aGWgNK' }}*/}
                        {/*    */}{/*<Elements stripe={stripePromise} options={{ clientSecret, }} >*/}
                        {/*    */}{/*    <PaymentElement />*/}
                        {/*    */}{/*</Elements>*/}
                        {/*    <hr className="my-4" />*/}
                        {/*    {!(paymentReady && getItemsCount() !== 0) ? 'disabled' : 'not disabled'}*/}
                        {/*    <button className="w-100 btn btn-primary btn-lg" type="submit" disabled={!(paymentReady && getItemsCount() !== 0)} >Process Transaction</button>*/}
                        {/*</form>*/}
                        <hr className="my-4" />
                        <div className="row g-3">
                            <div className="col-sm-8">
                                <Payment key={"Payment" + cont} disabled={isPaymentDisabled()} amount={getItemsTotal()} transactionCompleted={processTransaction} />
                            </div>
                            <div className="col-sm-4">
                                <button type="button" className="w-100 btn btn-danger btn-lg" onClick={clearTransaction} disabled={false} >
                                    {false ?
                                        <FontAwesomeIcon icon={faSpinner} spin className="me-2 me-sm-2 me-md-0 me-lg-2" />
                                        :
                                        <FontAwesomeIcon icon={faTrashCan} className="me-2 me-sm-2 me-md-0 me-lg-2" />
                                    }
                                    <span className="d-inline-block d-sm-inline-block d-md-none d-lg-inline-block">Clear</span>
                                </button>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-7 col-lg-8">
                        <nav className="nav nav-borders pb-3" >
                            <button onClick={(e) => { e.preventDefault(); setProductGroupFilter(null); }} className={`nav-link ${productGroupFilter == null ? 'active' : ''}`}><FontAwesomeIcon icon={faUser} className="pe-2" />Competitors</button>
                            <button onClick={(e) => { e.preventDefault(); setProductGroupFilter('SPECTATORS'); }} className={`nav-link ${productGroupFilter === 'SPECTATORS' ? 'active' : ''}`}><FontAwesomeIcon icon={faUsersLine} className="pe-2" />Spectators</button>
                            {getAddtionalProductsGroups().map((grp, i) =>
                                <button key={i} onClick={(e) => { e.preventDefault(); setProductGroupFilter(grp.price_name); }} className={`nav-link text-capitalize ${productGroupFilter && productGroupFilter === grp.price_name ? 'active' : ''}`}><FontAwesomeIcon icon={getCardIcon(grp.price_name)} className="pe-2" />{grp.price_name.toLowerCase()}</button>
                            )}
                        </nav>
                        <div className="row dashboard justify-content-center">
                            {!productGroupFilter ?
                                <DashboardCard icon={faUser} title="Add Event To Competitor" onClick={() => openCompetitorLookup()} />
                                : null}
                            {productGroupFilter && productGroupFilter === 'SPECTATORS' ? <>
                                <DashboardCard icon={faUsersLine} title={<>Regular<br /><NumericFormat value={spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount ? spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount : 15} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addSpectatorItem({ pass_type: 'Regular', price: spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount ? spectatorPricing.find(x => x.pass_type === "REGULAR").price_amount : 15})} />
                                <DashboardCard icon={faUsersLine} title={<>
                                    <FontAwesomeIcon icon={faStar} className="ps-2 text-warning" />
                                    <FontAwesomeIcon icon={faStar} className="pe-2 text-warning" />
                                    VIP
                                    <FontAwesomeIcon icon={faStar} className="ps-2 text-warning" />
                                    <FontAwesomeIcon icon={faStar} className="pe-2 text-warning" />
                                    <br /><NumericFormat value={spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "VIP").price_amount ? spectatorPricing.find(x => x.pass_type === "VIP").price_amount : 25} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addSpectatorItem({ pass_type: 'VIP', price: spectatorPricing && spectatorPricing.find(x => x.pass_type === "REGULAR") && spectatorPricing.find(x => x.pass_type === "VIP").price_amount ? spectatorPricing.find(x => x.pass_type === "VIP").price_amount : 25 })} />
                            </>
                                : null}
                            {getAdditionalProducts().map((prd, i) =>
                                <DashboardCard icon={getCardIcon(prd.price_name)} iconAlt={prd.price_name} title={<>{prd.price_description}<br /><NumericFormat value={prd.price_amount} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></>} onClick={() => addAdditionalItem(prd)} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-5">
                    {/*{searchResults ? searchResults.map((comp, i) => */}
                    {/*    <div>*/}
                    {/*        {comp.first_name} */}
                    {/*        {comp.last_name} */}
                    {/*        {comp.school_name}*/}
                    {/*        {comp.profile_image_url ?*/}
                    {/*            <img src={comp.profile_image_url} height="50" />*/}
                    {/*            : null}*/}
                    {/*    </div>*/}
                    {/*)*/}
                    {/*    : null}*/}
                    
                    <Modal isOpen={modalCompetitorLookup} toggle={() => toggleCompetitorLookup()} size="xl" fade={true}>
                        <ModalHeader toggle={() => toggleCompetitorLookup()}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />Competitor Lookup
                        </ModalHeader>
                        <ModalBody className="">
                            {/*<SearchCompetitorWithResults itemClicked={searchItemClicked} />*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => toggleCompetitorLookup()}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalCompetitorDetail} toggle={() => toggleCompetitorDetail(false)} size="xl" fade={true}>
                        <ModalHeader toggle={() => toggleCompetitorDetail(false)}>
                            <FontAwesomeIcon icon={faUser} className="pe-2" />Competitor Detail
                        </ModalHeader>
                        <ModalBody className="">
                            {/*<CompetitorDetail competitorDetail={competitorDetail} editEvents={true} handleEventSelect={handleEventSelected} eventsSelected={eventsToAdd} />*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => toggleCompetitorDetail(false)}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={addEventsForCompetitor} disabled={!(eventsToAdd && eventsToAdd.length > 0)}>
                                Add Events
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modalTransactionComplete} toggle={() => toggleModalTransactionComplete(false)} size="xl" fade={true} backdrop="static"> {/*backdrop="static"*/}
                        <ModalHeader>
                            <FontAwesomeIcon icon={faThumbsUp} className="pe-2" />Transaction Complete
                        </ModalHeader>
                        <ModalBody className="">
                            <div className="row g-3">
                                <div className="col-sm-12 col-md-12 col-lg-6 order-lg-last">
                                    {/*<h1 className="display-6 pt-3">Transaction Complete</h1>*/}
                                    {transactionRegistration ?
                                        <>
                                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="text-secondary">Registration</span>
                                                <span className="badge bg-secondary rounded-pill">#{transactionRegistration.registrationId}</span>
                                            </h4>
                                            <div className="card p-2 mb-3">
                                                <div className="row d-flex justify-content-between align-items-center g-3 px-2">
                                                    <div className="col text-center">
                                                        <SendRegistrationConfirmation disabled={!(profile && isEmail(profile.email))} registrationId={transactionRegistration.registrationId} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                    {/*<h1 className="display-6 pt-3">Transaction Complete</h1>*/}
                                    {profile ?
                                        <>
                                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="text-secondary">Profile</span>
                                                <span className="badge bg-secondary rounded-pill"></span>
                                            </h4>
                                            <div className="card p-2 mb-3">
                                                <div className="row d-flex g-3 px-2">
                                                    <div className="col-12">
                                                        <h6>Profile #{profile.profileId}</h6>
                                                        <h6>{profile.firstName} {profile.lastName}</h6>
                                                        <h6>{profile.email}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                </div>
                                <div className="col">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-secondary">Transaction Summary</span>
                                        <span className="badge bg-secondary rounded-pill">{getItemsCount()}</span>
                                    </h4>
                                    <ul className="list-group mb-3">
                                        {competitorItems ?
                                            competitorItems.map((itm, i) =>
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                    <div className="col ps-1">
                                                        <h6 className="my-0">{itm.competitor_detail.first_name} {itm.competitor_detail.last_name}</h6>
                                                        <small className="text-body-secondary">
                                                            {itm.events ? itm.events.map((e, ix) =>
                                                                <>
                                                                    {e.event_type_name}{e.team_event_flag ? ` (${e.team_name})` : null}{ix < itm.events.length - 1 ? ', ' : null}
                                                                </>
                                                            )
                                                                :
                                                                null
                                                            }
                                                        </small>
                                                    </div>
                                                    <span className="text-body-secondary">
                                                        <NumericFormat value={getCompetitorItemTotal(itm)} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                                    </span>
                                                </li>
                                            ) : null}
                                        {spectatorItems ?
                                            spectatorItems.map((itm, i) =>
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                    <div className="col-1 justify-content-center text-end">
                                                        {itm.quantity}
                                                    </div>
                                                    <div className="col-1 text-secondary text-center">
                                                        <small className="">X</small>
                                                    </div>
                                                    <div className="col ps-1">
                                                        <h6 className="my-0">Spectator - {itm.pass_type} <small className="text-body-secondary">(<NumericFormat value={itm.price} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />)</small></h6>
                                                    </div>
                                                    <span className="text-body-secondary"><NumericFormat value={itm.price * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                                </li>
                                            ) : null}
                                        {additionalItems ?
                                            additionalItems.map((itm, i) =>
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                    <div className="col-1 justify-content-center text-end">
                                                        {itm.quantity}
                                                    </div>
                                                    <div className="col-1 text-secondary text-center">
                                                        <small className="">X</small>
                                                    </div>
                                                    <div className="col ps-1">
                                                        <h6 className="my-0">{itm.price_description} <small className="text-body-secondary">(<NumericFormat value={itm.price_amount} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," />)</small></h6>
                                                    </div>
                                                    <span className="text-body-secondary"><NumericFormat value={itm.price_amount * itm.quantity} displayType="text" prefix="$" decimalScale={0} fixedDecimalScale thousandSeparator="," /></span>
                                                </li>
                                            ) : null}
                                        {appliedPromoCode && !appliedPromoCode.is_expired && !appliedPromoCode.is_exceeded && appliedPromoCode.discount_amount ?
                                            <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                                                <div className="col ps-1 text-success">
                                                    <h6 className="my-0">Promo Code: <b>{appliedPromoCode.discount_cd}</b></h6>
                                                    <small>{appliedPromoCode.discount_description}</small>
                                                </div>
                                                <span className="text-success"><NumericFormat value={getDiscountAmount(appliedPromoCode)} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></span>
                                            </li>
                                            :
                                            null
                                        }
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span>Total</span>
                                            <strong><NumericFormat value={getItemsTotal()} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /></strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-center">
                            <Button className="col-12 col-sm-12 col-xl-3 col-lg-6 col-md-9" color="primary" onClick={() => {
                                clearTransaction();
                                toggleModalTransactionComplete();
                            }}>
                                Continue
                            </Button>
                        </ModalFooter>
                    </Modal>

                </div>

            </div>
            {/*<div className="row g-5">*/}
            {/*    <div className="col-md-5 col-lg-4 order-md-last">*/}
            {/*        <h4 className="d-flex justify-content-between align-items-center mb-3">*/}
            {/*            <span className="text-primary">Your cart</span>*/}
            {/*            <span className="badge bg-primary rounded-pill">3</span>*/}
            {/*        </h4>*/}
            {/*        <ul className="list-group mb-3">*/}
            {/*            <li className="list-group-item d-flex justify-content-between lh-sm">*/}
            {/*                <div>*/}
            {/*                    <h6 className="my-0">Product name</h6>*/}
            {/*                    <small className="text-body-secondary">Brief description</small>*/}
            {/*                </div>*/}
            {/*                <span className="text-body-secondary">$12</span>*/}
            {/*            </li>*/}
            {/*            <li className="list-group-item d-flex justify-content-between lh-sm">*/}
            {/*                <div>*/}
            {/*                    <h6 className="my-0">Second product</h6>*/}
            {/*                    <small className="text-body-secondary">Brief description</small>*/}
            {/*                </div>*/}
            {/*                <span className="text-body-secondary">$8</span>*/}
            {/*            </li>*/}
            {/*            <li className="list-group-item d-flex justify-content-between lh-sm">*/}
            {/*                <div>*/}
            {/*                    <h6 className="my-0">Third item</h6>*/}
            {/*                    <small className="text-body-secondary">Brief description</small>*/}
            {/*                </div>*/}
            {/*                <span className="text-body-secondary">$5</span>*/}
            {/*            </li>*/}
            {/*            <li className="list-group-item d-flex justify-content-between bg-body-tertiary">*/}
            {/*                <div className="text-success">*/}
            {/*                    <h6 className="my-0">Promo code</h6>*/}
            {/*                    <small>EXAMPLECODE</small>*/}
            {/*                </div>*/}
            {/*                <span className="text-success">-$5</span>*/}
            {/*            </li>*/}
            {/*            <li className="list-group-item d-flex justify-content-between">*/}
            {/*                <span>Total (USD)</span>*/}
            {/*                <strong>$20</strong>*/}
            {/*            </li>*/}
            {/*        </ul>*/}

            {/*        <form className="card p-2">*/}
            {/*            <div className="input-group">*/}
            {/*                <input type="text" className="form-control" placeholder="Promo code" />*/}
            {/*                <button type="submit" className="btn btn-secondary">Redeem</button>*/}
            {/*            </div>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*    <div className="col-md-7 col-lg-8">*/}
            {/*        <h4 className="mb-3">Billing address</h4>*/}
            {/*        <form className="needs-validation" novalidate="">*/}
            {/*            <div className="row g-3">*/}
            {/*                <div className="col-sm-6">*/}
            {/*                    <label for="firstName" className="form-label">First name</label>*/}
            {/*                    <input type="text" className="form-control" id="firstName" placeholder="" value="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Valid first name is required.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-sm-6">*/}
            {/*                    <label for="lastName" className="form-label">Last name</label>*/}
            {/*                    <input type="text" className="form-control" id="lastName" placeholder="" value="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Valid last name is required.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-12">*/}
            {/*                    <label for="username" className="form-label">Username</label>*/}
            {/*                    <div className="input-group has-validation">*/}
            {/*                        <span className="input-group-text">@</span>*/}
            {/*                        <input type="text" className="form-control" id="username" placeholder="Username" required="" />*/}
            {/*                        <div className="invalid-feedback">*/}
            {/*                            Your username is required.*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-12">*/}
            {/*                    <label for="email" className="form-label">Email <span className="text-body-secondary">(Optional)</span></label>*/}
            {/*                    <input type="email" className="form-control" id="email" placeholder="you@example.com" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Please enter a valid email address for shipping updates.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-12">*/}
            {/*                    <label for="address" className="form-label">Address</label>*/}
            {/*                    <input type="text" className="form-control" id="address" placeholder="1234 Main St" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Please enter your shipping address.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-12">*/}
            {/*                    <label for="address2" className="form-label">Address 2 <span className="text-body-secondary">(Optional)</span></label>*/}
            {/*                    <input type="text" className="form-control" id="address2" placeholder="Apartment or suite" />*/}
            {/*                </div>*/}

            {/*                <div className="col-md-5">*/}
            {/*                    <label for="country" className="form-label">Country</label>*/}
            {/*                    <select className="form-select" id="country" required="">*/}
            {/*                        <option value="">Choose...</option>*/}
            {/*                        <option>United States</option>*/}
            {/*                    </select>*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Please select a valid country.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-md-4">*/}
            {/*                    <label for="state" className="form-label">State</label>*/}
            {/*                    <select className="form-select" id="state" required="">*/}
            {/*                        <option value="">Choose...</option>*/}
            {/*                        <option>California</option>*/}
            {/*                    </select>*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Please provide a valid state.*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-md-3">*/}
            {/*                    <label for="zip" className="form-label">Zip</label>*/}
            {/*                    <input type="text" className="form-control" id="zip" placeholder="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Zip code required.*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <hr className="my-4" />*/}

            {/*            <div className="form-check">*/}
            {/*                <input type="checkbox" className="form-check-input" id="same-address" />*/}
            {/*                <label className="form-check-label" for="same-address">Shipping address is the same as my billing address</label>*/}
            {/*            </div>*/}

            {/*            <div className="form-check">*/}
            {/*                <input type="checkbox" className="form-check-input" id="save-info" />*/}
            {/*                <label className="form-check-label" for="save-info">Save this information for next time</label>*/}
            {/*            </div>*/}

            {/*            <hr className="my-4" />*/}

            {/*            <h4 className="mb-3">Payment</h4>*/}

            {/*            <div className="my-3">*/}
            {/*                <div className="form-check">*/}
            {/*                    <input id="credit" name="paymentMethod" type="radio" className="form-check-input" checked="" required="" />*/}
            {/*                    <label className="form-check-label" for="credit">Credit card</label>*/}
            {/*                </div>*/}
            {/*                <div className="form-check">*/}
            {/*                    <input id="debit" name="paymentMethod" type="radio" className="form-check-input" required="" />*/}
            {/*                    <label className="form-check-label" for="debit">Debit card</label>*/}
            {/*                </div>*/}
            {/*                <div className="form-check">*/}
            {/*                    <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" required="" />*/}
            {/*                    <label className="form-check-label" for="paypal">PayPal</label>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="row gy-3">*/}
            {/*                <div className="col-md-6">*/}
            {/*                    <label for="cc-name" className="form-label">Name on card</label>*/}
            {/*                    <input type="text" className="form-control" id="cc-name" placeholder="" required="" />*/}
            {/*                    <small className="text-body-secondary">Full name as displayed on card</small>*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Name on card is required*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-md-6">*/}
            {/*                    <label for="cc-number" className="form-label">Credit card number</label>*/}
            {/*                    <input type="text" className="form-control" id="cc-number" placeholder="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Credit card number is required*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-md-3">*/}
            {/*                    <label for="cc-expiration" className="form-label">Expiration</label>*/}
            {/*                    <input type="text" className="form-control" id="cc-expiration" placeholder="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Expiration date required*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="col-md-3">*/}
            {/*                    <label for="cc-cvv" className="form-label">CVV</label>*/}
            {/*                    <input type="text" className="form-control" id="cc-cvv" placeholder="" required="" />*/}
            {/*                    <div className="invalid-feedback">*/}
            {/*                        Security code required*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <hr className="my-4" />*/}

            {/*            <button className="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</button>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    );

}
export default POS;
