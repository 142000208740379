import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useContextTournament from '../useContextTournament';

function PromoCode(props) {
    const { tournamentId } = useContextTournament();
    const [promoCode, setPromoCode] = useState('');
    const [applied, setApplied] = useState(false);
    const [appliedCode, setAppliedCode] = useState(null);

    const [searching, setSearching] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [searchFor, setSearchFor] = useState('');

    //const authUsername = GetAuthUsername();

    useEffect(() => {
    }, []);
    const changePromoCode = (e) => {
        setApplied(false);
        setPromoCode(e.target.value);
    }
    const applyCode = async (e) => {
        e.preventDefault();
        setSearching(true);
        setApplied(false);
        let respCode = null;
        try {
            const response = await fetch(`/api/pos/${tournamentId}/promocode?promoCode=` + encodeURIComponent(promoCode));
            const rdata = await response.json();
            respCode = rdata;
            setAppliedCode(rdata);
            if (props.appliedCode) {
                props.appliedCode(rdata);
            }
        }
        catch {
            setAppliedCode(null);
            if (props.appliedCode) {
                props.appliedCode(null);
            }
        }
        setApplied(true);
        setSearching(false);
        if (respCode)
        {
            if (!(respCode.is_expired || respCode.is_exceeded)) {
                setPromoCode('');
            }
        }

        //validate the code against the API
        //raise the event to the parent prop
        //show the code, description, and discount amount
        //Change the button to redeem a different code
        //    if (props.changedSearchFor) {
        //        props.changedSearchFor(e);
        //    }
        //    setSearchFor(e.target.value);
    };



    return (
        //<form onSubmit={searchCompetitors}>
        //    <div className="input-group input-group-lg">
        //        <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" value={searchFor} onChange={handleSearchForChange} />
        //        <button className="btn btn-primary" type="submit">
        //            <FontAwesomeIcon icon={searching ? faSpinner : faMagnifyingGlass} spin={searching} className="" />
        //        </button>
        //    </div>
        //</form>
        <form onSubmit={applyCode} className="card p-2">
            <div className="input-group">
                <input type="text" className="form-control" onChange={changePromoCode} value={promoCode} placeholder="Promo Code" />
                <button type="submit" className="btn btn-secondary" disabled={!(promoCode && promoCode !== '')}><FontAwesomeIcon icon={searching ? faSpinner : faTicket} spin={searching} className="pe-2" /> Apply</button>
            </div>
            {appliedCode === null && applied ?
                <small className="px-1 text-danger">
                    This code is invalid.
                </small> :
                appliedCode && appliedCode.is_expired ?
                    <small className="px-1 text-danger">
                        This code is expired.
                    </small> :
                    appliedCode && appliedCode.is_exceeded ?
                        <small className="px-1 text-danger">
                            The is code is no longer valid.
                        </small> :
                        null
            }
        </form>
    );

}
export default PromoCode;
